import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

const MODAL_SELECTOR = '#send-gift-modal';
const MODAL_CONTENT_SELECTOR = '.js-send-gift-modal--content';
const CALENDAR_BUTTON_SELECTOR = '.js-calendar-button';

export default class extends Controller {
  static targets = ['icon', 'error', 'sendLaterDate', 'sendLaterCheckbox'];

  connect() {
    // We can't set this as a target as the element already has a
    // data-target="modal" attribute, so getting it here
    this.calendarButton = this.element.querySelector(CALENDAR_BUTTON_SELECTOR);
  }

  openModal() {
    event.preventDefault();

    Rails.ajax({
      url: this.data.get('url'),
      type: 'GET',
      dataType: 'json',
      success: data => {
        injectModalContent(data.modal_content_html);
      }
    });
  }

  ajaxSuccess(event) {
    const orderId = event.detail[0].order_id;
    const successMessage = event.detail[0].success_message;
    const element = document.querySelector(
      `[data-order-id="${orderId}"] .js-send-gift-success-receiver`
    );

    if (window.location.pathname == '/orders') {
      let update_message = document.querySelector(`.message-${orderId}`);
      update_message.innerHTML = `
        <div class='alert alert-success mt-0 d-inline-block rounded'>
        <p class='mb-0'>${successMessage}</p>
        </div>
      `;
    } else {
      element.innerHTML = event.detail[0].html;
    }

    element.classList.remove('d-none');
    $(MODAL_SELECTOR).modal('hide');
  }

  ajaxError(event) {
    const html = event.detail[0].modal_content_html;

    if (html) {
      console.log('here');
      injectModalContent(event.detail[0].modal_content_html);
    }
  }

  checkDate(event) {
    if (this.sendLaterCheckboxTarget.checked) {
      let errorMsg;
      if (!this.sendLaterDateTarget.value) {
        errorMsg = 'Please select a date';
      } else if (
        this.sendLaterDateTarget.value ===
        this.element.dataset.dateToday.replace(/"/g, '')
      ) {
        errorMsg = 'Please select a date in the future';
      }

      if (errorMsg) {
        event.preventDefault();
        this.addError(errorMsg);
      }
    }
  }

  addError(msg) {
    this.errorTarget.textContent = msg;
    this.errorTarget.classList.add('d-block');
    this.calendarButton.classList.add('border-danger');
  }

  removeError() {
    this.errorTarget.classList.remove('d-block');
    this.errorTarget.textContent = '';
    this.calendarButton.classList.remove('border-danger');
  }
}

function injectModalContent(html) {
  document.querySelector(MODAL_CONTENT_SELECTOR).innerHTML = html;
  $(MODAL_SELECTOR).modal('show');
}
