/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import '../javascript/vendor';

import '../javascript/lib/ajaxWaitingState';
import '../javascript/lib/refreshCSRFTokens';
import { checkForInitYuupMap } from '../javascript/lib/map';
import '../javascript/lib/mapMulitpleMarkers';
import '../javascript/lib/confirmBeforeExitForm';
import '../javascript/lib/searchFilters';
import '../javascript/lib/turbolinksBeforeCache';
import '../javascript/lib/autoSubmitForm';
import '../javascript/lib/initSortable';
import '../javascript/lib/initFacebookLogin';
import initValidation from '../javascript/lib/validateFields';
import initBookingCalendar from '../javascript/lib/bookingCalendar';
import initSupplierCalendar from '../javascript/lib/supplierCalendar';
import initfilterCalendar from '../javascript/lib/filterCalendar';
import initPostcodeLookups from '../javascript/lib/lookupPostcodes';
import initStickyBar from '../javascript/lib/stickyBar';
import experienceAnimationViewportCheck from '../javascript/lib/experienceAnimationViewportCheck';
import turbolinksScrollPersistence from '../javascript/lib/turbolinksScrollPersistence';
import initTabNavigation from '../javascript/lib/tabNavigation';

document.documentElement.classList.remove('no-js');

initValidation();
initPostcodeLookups();
initfilterCalendar();
initBookingCalendar();
initSupplierCalendar();
initStickyBar();
window.initBookingCalendar = initBookingCalendar;
experienceAnimationViewportCheck();
turbolinksScrollPersistence();
initTabNavigation();

window.initMap = checkForInitYuupMap;

require.context('../images', true);
