// Media Query should match the same width as .y-container--x-large
const MEDIA_QUERY = window.matchMedia('(min-width: 1250px)');
let ANIMATION_CONTROLLERS;

export default function experienceAnimationViewportCheck() {
  document.addEventListener('turbolinks:load', () => {
    checkViewport();
  });
  checkViewport();

  document.addEventListener('turbolinks:before-cache', () => {
    MEDIA_QUERY.removeListener(WidthChange);
  });
}

function checkViewport() {
  ANIMATION_CONTROLLERS = [
    ...document.querySelectorAll('.y-experience-animation-controller')
  ];

  // If there are experience images, listen out for viewport resize
  // Otherwise bail

  if (!ANIMATION_CONTROLLERS.length) return;

  if (matchMedia) {
    MEDIA_QUERY.addListener(WidthChange);
    WidthChange(MEDIA_QUERY);
  }
}

function WidthChange(media_query) {
  for (let controller of ANIMATION_CONTROLLERS) {
    if (media_query.matches) {
      controller.setAttribute('data-controller', 'experienceAnimation');
    } else {
      controller.removeAttribute('data-controller');
    }
  }
}
