import { BaseController } from 'stimulus-library';
import TomSelect from 'tom-select';

// A multi-select box for cities (root locations). Optionally references checkboxes for sublocations
// so they can be checked or unchecked based on the selection.
export default class extends BaseController {
  static values = {
    options: Array,
    items: Array, // The options that are selected initially
    lastChoice: String
  };
  static targets = ['city', 'sublocation'];

  initialize() {
    this.updateSublocations = this.updateSublocations.bind(this);
    this.updateCities = this.updateCities.bind(this);
  }

  connect() {
    // Restore the selected cities from checked location boxes if no items are provided
    if (this.itemsValue.length === 0) {
      const seen = Array.from(
        this.sublocationTargets
          .filter(element => element.checked)
          .reduce((acc, element) => acc.add(element.dataset.city), new Set())
      );
      this.itemsValue = Array.from(new Set([...seen, ...this.itemsValue]));
    }
    //

    this.toms = [];
    this.cityTargets.forEach(e => {
      const tom = new TomSelect(e, {
        valueField: 'id',
        labelField: 'title',
        searchField: 'title',
        placeholder: 'Anywhere',
        hidePlaceholder: true,
        plugins: ['checkbox_options', 'remove_button'],
        options: this.optionsValue,
        items: this.itemsValue,

        onChange: this.updateSublocations
      });

      this.toms.push(tom);
    });

    this.sublocationTargets.forEach(sublocation => {
      sublocation.addEventListener('change', this.updateCities);
    });

    this.lastChoiceValue = this.itemsValue;
  }

  disconnect() {
    this.toms.forEach(tom => {
      tom.destroy();
    });
    this.sublocationTargets.forEach(sublocation => {
      sublocation.removeEventListener('change', this.updateCities);
    });
  }

  // If a user has selected or deselected a city, select/deselect all
  // its sublocations. Otherwise don't do anything.
  updateSublocations(selectedCities) {
    const currentChoice = new Set(selectedCities.split(','));
    const lastChoice = new Set(this.lastChoiceValue.split(','));

    const toSelect = new Set(
      [...currentChoice].filter(x => !lastChoice.has(x))
    );
    const toDeselect = new Set(
      [...lastChoice].filter(x => !currentChoice.has(x))
    );

    this.sublocationTargets.forEach(sublocation => {
      const city = sublocation.dataset.city;

      if (toSelect.has(city)) {
        sublocation.checked = true;
      } else if (toDeselect.has(city)) {
        sublocation.checked = false;
      }
    });

    if (this.lastChoiceValue !== selectedCities) {
      this.lastChoiceValue = selectedCities;
      this.dispatch('changed', { selected: this.selectedCities });
    }
  }

  // When a sublocation box is checked or unchecked, we want to make sure the city
  // picker reflects that.
  updateCities(event) {
    const siblingsChecked = this.sublocationTargets.filter(sublocation => {
      return (
        sublocation.dataset.city === event.target.dataset.city &&
        sublocation.checked
      );
    }).length;

    if (siblingsChecked === 0) {
      this.toms.forEach(tom => {
        tom.removeItem(event.target.dataset.city);

        // Not sure why, but the placeholder gets hidden if this method
        // removes the last city otherwise
        if (this.lastChoiceValue === '') {
          tom.wrapper.classList.remove('input-hidden');
          tom.wrapper.classList.add('input-active');
        }
      });
    } else if (siblingsChecked === 1) {
      this.toms.forEach(tom => {
        const delimiter = this.lastChoiceValue === '' ? '' : ',';
        // We're adding the city, but we don't want the updateSublocations to check every box
        this.lastChoiceValue += delimiter + event.target.dataset.city;

        tom.addItem(event.target.dataset.city);
      });
    }
  }
}
