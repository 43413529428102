import searchIcon from '../../images/icons/fa/far-search-location.png';

export default function init() {
  $(document).on('turbolinks:load', () => {
    initPostcodeLookups();
  });
  initPostcodeLookups();
}

function initPostcodeLookups() {
  const apiKey = window.idealPostcodeApiKey;

  const $experienceLookupField = $('.js-postcode-lookup-field');

  if ($experienceLookupField) {
    $experienceLookupField.html('');
    $experienceLookupField.setupPostcodeLookup({
      api_key: apiKey,
      output_fields: {
        line_1: '#line_1',
        line_2: '#line_2',
        post_town: '#city',
        postcode: '#postal_code'
      },
      input: '#postal_code',
      button_class: 'btn btn-secondary mb-3 flex-center',
      button_label: `<img src=${searchIcon} class='y-icon -small mr-2' alt=''>Find my address`,
      dropdown_class: 'custom-select'
    });
  }

  const $supplierLookupField = $('#lookup_field');

  if ($supplierLookupField) {
    $supplierLookupField.html('');

    $supplierLookupField.setupPostcodeLookup({
      // Add your API key
      api_key: apiKey,
      // Identify your fields with CSS selectors
      output_fields: {
        line_1: '#supplier_profile_address_attributes_line_1',
        line_2: '#supplier_profile_address_attributes_line_2',
        line_3: '#supplier_profile_address_attributes_line_3',
        post_town: '#supplier_profile_address_attributes_city',
        postcode: '#supplier_profile_address_attributes_postal_code'
      },
      input: '#supplier_profile_address_attributes_postal_code',
      button_label: `<img src=${searchIcon} class='y-icon -small mr-2' alt=''>Find my address`,
      button_class: 'btn btn-secondary flex-center',
      dropdown_id: 'idpc_dropdown',
      dropdown_class: 'custom-select',
      dropdown_select_message:
        'Choose your business address from the list below'
    });
  }

  const $spaceLookupField = $('#lookup_space_field');

  if ($spaceLookupField) {
    $spaceLookupField.html('');
    const $firstLineAddressInput = $('.js-address-first-line-group');

    $spaceLookupField.setupPostcodeLookup({
      // Add your API key
      api_key: apiKey,
      // Identify your fields with CSS selectors
      output_fields: {
        line_1: '#space_address_line_1',
        postcode: '#space_postal_code'
      },
      input: '#space_postal_code',
      button_label: `<img src=${searchIcon} class='y-icon -small mr-2' alt=''>Find my address`,
      button_class: 'btn btn-secondary flex-center',
      dropdown_id: 'idpc_dropdown',
      dropdown_class: 'custom-select mt-4',
      dropdown_select_message:
        'Choose your business address from the list below',
      onSearchCompleted: function(data) {
        // Toggle first line of address if postcode can't be found
        if (data.code === 2000) {
          $firstLineAddressInput.addClass('d-none');
        } else {
          $firstLineAddressInput.removeClass('d-none');
        }
      }
    });
  }
}
