import { BaseController } from 'stimulus-library';

// controls 3 banners : a countdown, a seats released message, and a
// homepage promotional banner that is temporarily replaced by the first two.
export default class SelectionHoldCountdownController extends BaseController {
  static targets = ['countdown', 'countdownContainer', 'seatsReleased', 'bannerMessage']

  static values = {
    basketHasBeenEmptied: Boolean,
    basketHasSeatsOrder: Boolean,
    onBasket: Boolean,
    onHomepage: Boolean,
  }

  connect() {
    if (this.basketHasBeenEmptiedValue || !this.basketHasSeatsOrderValue) {
      this.userOwnsSeats = false;
    }

    if (this.basketHasSeatsOrderValue) {
      this.userOwnsSeats = true;
    }

    if (this.userOwnsSeats && this.timeRemaining > 0) {
        this.updateCountdownTime();
        this.startCountdown();
        this.enableBanner('countdown');
        localStorage.removeItem("ySeatsReleasedBannerShownLastTime");
    } else {
      this.maybeSaySeatsReleased();
    }

    document.addEventListener('seatsSessionInitialized', this.onSeatsSessionInitialized.bind(this))
  }

  disconnect() {
    clearInterval(this.interval)
  }

  // always say seats released on the basket page
  //
  // on other pages only say it if released recently (after 1 day, show banner 1 more time)
  //
  // on homepage, if not showing seats released banner, show the promo banner
  maybeSaySeatsReleased() {
    if (this.userOwnsSeats  && (this.onBasketValue || this.isReleasedRecently())) {
      this.enableBanner('seatsReleased');
    } else {
      if (this.onHomepageValue) {
        this.enableBanner('bannerMessage');
      }
    }
  }

  // 1 day in milliseconds
  static AGE_CONSIDERED_RECENT = 24 * 3600 * 1000;

  // returns Boolean
  isReleasedRecently() {
    if (Date.now() < (this.endTime.getTime() + this.constructor.AGE_CONSIDERED_RECENT)) {
      return true;
    };

    if (localStorage.getItem("ySeatsReleasedBannerShownLastTime")) {
      return false; // don't show until flag reset
    } else {
      localStorage.setItem("ySeatsReleasedBannerShownLastTime", true);
      return true; // show one last time
    }
  }

  enableBanner(type) {
    switch (type) {
      case 'seatsReleased':
        this.seatsReleasedTarget.hidden = false
        this.bannerMessageTarget.hidden = true
        this.countdownContainerTarget.hidden = true
        break
      case 'bannerMessage':
        this.seatsReleasedTarget.hidden = true
        this.bannerMessageTarget.hidden = false
        this.countdownContainerTarget.hidden = true
        break
      case 'countdown':
        this.seatsReleasedTarget.hidden = true
        this.bannerMessageTarget.hidden = true
        this.countdownContainerTarget.hidden = false
        break
    }
  }

  disableBanners() {
    this.seatsReleasedTarget.hidden = true
    this.bannerMessageTarget.hidden = true
    this.countdownContainerTarget.hidden = true
  }

  onSeatsSessionInitialized(event) {
    this.endTime = new Date(event.detail.expiresInSeconds * 1000 + Date.now())

    this.startCountdown()
  }

  startCountdown() {
    if (this.interval) return

    this.enableBanner('countdown')

    this.interval = setInterval(() => {
      if (this.timeRemaining <= 0) {
        this.stopCountdown()
      }

      this.updateCountdownTime()
    }, 1000)
  }

  updateCountdownTime() {
    const minutes = Math.floor(this.timeRemaining / 60)
    const seconds = this.timeRemaining % 60

    this.countdownTarget.textContent = `${minutes}:${(seconds <= 9 ? '0' : '') + seconds}`
  }

  stopCountdown() {
    this.disableBanners()

    if (this.userOwnsSeats) {
      this.enableBanner('seatsReleased')
    } else if (this.onHomepageValue) {
      this.enableBanner('bannerMessage')
    }

    clearInterval(this.interval)

    this.countdownTarget.textContent = '0:00'
  }

  get timeRemaining() {
    const endTime = this.endTime

    if (endTime) {
      return Math.max(Math.floor((endTime.getTime() - Date.now()) / 1000), 0)
    }
  }

  get endTime() {
    const endTime = localStorage.getItem('seatsEndTime')

    if (!isNaN(Number(endTime))) {
      return new Date(Number(endTime))
    }
  }

  set endTime(value) {
    return localStorage.setItem('seatsEndTime', value.getTime())
  }

  get userOwnsSeats() {
    return localStorage.getItem('seatsUserOwnsSeats') === 'true'
  }

  set userOwnsSeats(value) {
    return localStorage.setItem('seatsUserOwnsSeats', value)
  }
}
