import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

const MODAL_SELECTOR = '#simple-modal';
const MODAL_CONTENT_SELECTOR = '.js-send-simple-modal--content';

export default class extends Controller {
  static targets = ['icon', 'error'];

  openModal() {
    event.preventDefault();

    Rails.ajax({
      url: this.data.get('url'),
      type: 'GET',
      dataType: 'json',
      success: data => {
        injectModalContent(data.modal_content_html);
      }
    });
  }
}

function injectModalContent(html) {
  document.querySelector(MODAL_CONTENT_SELECTOR).innerHTML = html;
  $(MODAL_SELECTOR).modal('show');
}
