// Remove modals before tubolinks cache
$(document).on('turbolinks:before-cache', () => {
  $('.modal').modal('hide');
  $('.modal').hide();
  $('body').removeClass('modal-open modal-open--light');
  $('.modal-backdrop').remove();
});

document.addEventListener('turbolinks:load', () => {
  // Add a modifying class to the body to customise the
  // overlay opacity for this modal specifically

  const $searchModal = $('#modal-search');

  // A modifying class on the body for a lighter overlay

  $searchModal.on('show.bs.modal', function() {
    document.body.classList.add('modal-open--light');
  });

  // Auto focus the search field

  $searchModal.on('shown.bs.modal', function() {
    const $searchModalInput = $searchModal.find('#y-search-modal');
    $searchModalInput.focus();
    // Unless you re-fill the input, focus() will
    // add the cursor at the start of the input text
    const inputVal = $searchModalInput.val();
    $searchModalInput.val('').val(inputVal);
  });

  // Remove class when modal is closed

  $searchModal.on('hidden.bs.modal', function() {
    document.body.classList.remove('modal-open--light');
  });
});
