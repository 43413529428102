import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'text'];

  updateInputs() {
    if (event.target.value) {
      this.textTarget.removeAttribute('required');
      this.textTarget.removeAttribute('aria-required');
      this.textTarget.classList.remove('required');
    } else {
      this.textTarget.setAttribute('required', 'true');
      this.textTarget.setAttribute('aria-required', 'true');
      this.textTarget.classList.add('required');
    }
  }
}
