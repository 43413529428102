import { Controller } from 'stimulus';

// Add a collapse class to your collapse target
export default class extends Controller {
  static targets = ['checkbox', 'collapse'];

  change(e) {
    this.update(e.target.checked);
  }

  invertedChange(e) {
    this.update(!e.target.checked);
  }

  update(willShow) {
    if (willShow) {
      $(this.collapseTarget).collapse('show');
      this.checkboxTarget.setAttribute('aria-expanded', 'true');
    } else {
      $(this.collapseTarget).collapse('hide');
      this.checkboxTarget.setAttribute('aria-expanded', 'false');
    }
  }
}
