import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    $('#modal-search').on('shown.bs.modal', function() {
      $('#y-search-modal')[0].focus();
    });
  }
  toggleMenu() {
    document.body.classList.toggle('y-mobile-menu-active');
  }
}
