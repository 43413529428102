import { Controller } from 'stimulus';
import forEach from 'lodash/forEach';

const CLASS_CALENDAR_HIDE = 'y-calendar-hide';

export default class extends Controller {
  static values = {
    full: Boolean,
    normal: Boolean, // normal_book_later?
    gift: Boolean // gift_book_later?
  };
  static targets = ['revealable', 'radio'];

  initialize() {
    if (this.data.get('isopen')) {
      this.show();
    }
  }

  connect() {
    this.giftChanged();
  }

  enableRevealable() {
    changeDisabledState(this.revealableTarget, false);
  }

  disableRevealable() {
    changeDisabledState(this.revealableTarget, true);
  }

  show() {
    $(this.revealableTarget).collapse('show');
  }

  hide() {
    $(this.revealableTarget).collapse('hide');
  }

  schedule_now() {
    $('.js-schedule-now').removeClass(CLASS_CALENDAR_HIDE);
    $('#schedule_now').prop('checked', true);
    $('#schedule_later').prop('checked', false);
  }

  schedule_later() {
    // Using hide() blocks calendar being updated, so hiding manually
    $('.js-schedule-now').addClass(CLASS_CALENDAR_HIDE);
    $('#schedule_now').prop('checked', false);
    $('#schedule_later').prop('checked', true);
  }

  giftChanged(e) {
    const isGift = $('#order_gifted').prop('checked');
    const cantBookLater =
      (isGift && !this.giftValue) || (!isGift && !this.normalValue);
    const cantBookNow = this.fullValue;

    $('#schedule_later').prop('disabled', cantBookLater);
    if (cantBookLater) {
      this.schedule_now();
    } else if (cantBookNow) {
      this.schedule_later();
    }
  }

  setActiveTimeslot(event) {
    const radioButtons = [...this.radioTargets];
    radioButtons.forEach(radioButton => {
      radioButton.dataset.selectedTimeSlot = 'false';
    });

    event.target.dataset.selectedTimeSlot = 'true';
  }

  showIfRevealer() {
    if (event.target.dataset.revealer == 'true') {
      $(this.revealableTarget).collapse('show');
    } else {
      $(this.revealableTarget).collapse('hide');
    }
  }

  showIfChecked() {
    $(this.revealableTarget).collapse('toggle');
  }

  toggleRadio(event) {
    if (event.target.getAttribute('data-is-checked') == 'true') {
      event.target.setAttribute('data-is-checked', false);
      event.target.checked = false;
    } else {
      event.target.setAttribute('data-is-checked', true);
    }
    console.log(event.target.checked);
  }
}

function changeDisabledState(fieldset, disable) {
  forEach(fieldset.querySelectorAll('input'), input => {
    input.disabled = disable;
  });
}
