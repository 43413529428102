import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['radio', 'form', 'atHome', 'experienceLocations'];

  click(event) {
    let placeId = event.currentTarget.dataset.place;
    let addressId = event.currentTarget.dataset.address;

    this.radioTargets.find(field => field.value == placeId).checked = true;

    this.placeField.id = `experience_experience_place_attributes_id_${placeId}`;
    this.placeField.value = placeId;

    this.addressField.id = `experience_experience_place_attributes_address_attributes_id_${addressId}`;
    this.addressField.value = addressId;

    Rails.ajax({
      type: 'GET',
      url: event.currentTarget.dataset.url,
      dataType: 'json',
      success: data => {
        this.addressFormFields.find(field => field.id == 'postal_code').value =
          data.postal_code;
        this.addressFormFields.find(field => field.id == 'line_1').value =
          data.line_1;
        this.addressFormFields.find(field => field.id == 'line_2').value =
          data.line_2;
        this.addressFormFields.find(field => field.id == 'city').value =
          data.city;
      }
    });

    $(this.formTarget).collapse('toggle');
  }

  clearEditForm() {
    this.placeField.id = `experience_experience_place_attributes_id`;
    this.placeField.value = null;

    this.addressField.id = `experience_experience_place_attributes_address_attributes_id`;
    this.addressField.value = null;

    this.addressFormFields.find(
      field => field.id == 'postal_code'
    ).value = null;
    this.addressFormFields.find(field => field.id == 'line_1').value = null;
    this.addressFormFields.find(field => field.id == 'line_2').value = null;
    this.addressFormFields.find(field => field.id == 'city').value = null;
  }

  toggleLocationFields(event) {
    $(this.atHomeTarget).collapse('toggle');
    $(this.experienceLocationsTarget).collapse('toggle');

    if (event.currentTarget.checked) {
      $('#experience_at_home_instructions')[0].classList.remove('optional');
      $('#experience_at_home_instructions')[0].classList.add('required');
      $('#experience_at_home_instructions').prop('required', true);
    } else {
      $('#experience_at_home_instructions')[0].classList.remove('required');
      $('#experience_at_home_instructions')[0].classList.add('optional');
      $('#experience_at_home_instructions').prop('required', false);
    }
  }

  get addressFormFields() {
    return Array.from(this.formTarget.elements);
  }

  get placeField() {
    return this.addressFormFields.find(
      field => field.name == 'experience[experience_place_attributes][id]'
    );
  }

  get addressField() {
    return this.addressFormFields.find(
      field =>
        field.name ==
        'experience[experience_place_attributes][address_attributes][id]'
    );
  }
}
