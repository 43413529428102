import { Controller } from 'stimulus';

// The target textarea lives in a modal out of scope of this controller
const TARGET_SELECTOR = '.js-link-customer-notes-target';

export default class extends Controller {
  addToTarget() {
    if ($(TARGET_SELECTOR).length) {
      $(TARGET_SELECTOR).val(this.element.value);
    }
  }
}
