import 'lodash';

export default function initStickyBar() {
  document.addEventListener('turbolinks:load', () => {
    setupStickyBar();
  });

  setupStickyBar();
}

function setupStickyBar() {
  const stickyButton = document.querySelector('[data-sticky-target]'),
    stickyTarget = stickyButton ? stickyButton.dataset.stickyTarget : null,
    stickyTargetElement = stickyTarget
      ? document.querySelector(`.${stickyTarget}`)
      : null;

  if (stickyTargetElement) {
    window.addEventListener('scroll', handleStickyBar);
    document.addEventListener('turbolinks:before-cache', () => {
      teardownStickyBar();
    });
  }
}

const handleStickyBar = _.throttle(() => {
  const stickyTarget = document.querySelector('[data-sticky-target]'),
    stickyBar = document.querySelector('.y-sticky-bar');

  var target = stickyTarget;
  var position = target.getBoundingClientRect();

  if (position.bottom <= 0) {
    stickyBar.classList.add('y-sticky-bar--active');
  } else {
    stickyBar.classList.remove('y-sticky-bar--active');
  }
}, 800);

function teardownStickyBar() {
  window.removeEventListener('scroll', handleStickyBar);
  const stickyBar = document.querySelector('.y-sticky-bar');
  if (stickyBar) {
    document
      .querySelector('.y-sticky-bar')
      .classList.remove('y-sticky-bar--active');
  }
}
