import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['submenu'];

  connect() {
    $('.dropdown').on('hide.bs.dropdown', function() {
      $('.dropdown-submenu .dropdown-menu').hide();
    });
  }

  toggle() {
    event.preventDefault();
    event.stopPropagation();

    const otherSubmenus = $(event.target)
      .closest('.dropdown-menu')
      .find('.dropdown-menu')
      .not(this.element);
    otherSubmenus.toggle(false);

    $(this.submenuTarget).toggle();
  }
}
