import {BaseController} from 'stimulus-library';

/*
 * Sets all the values of its targets ("checkbox") to match a controlling checkbox.
 *
 * To use, initiate the #toggle event on change of a checkbox.
 *
 * (A super-simplified alternative to
 * https://github.com/Sub-Xaero/stimulus-library/blob/v0.7.4/src/controllers/forms/checkbox_select_all_controller.ts which does
 * other clever things like feed back the state of the checkbox targets to the controlling checkbox, and ignore disabled inputs.)
 */
export default class YuupCheckboxSelectAllController extends BaseController {
  static targets = ['checkbox'];

  toggle(event) {
    let target = event.target;
    this.checkboxTargets.forEach((checkbox) => checkbox.checked = target.checked);
  }
}
