import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    var script = document.createElement('script');
    script.src = 'https://www.instagram.com/embed.js';
    var head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  }
}
