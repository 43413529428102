import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'filters', 'results'];

  toggle() {
    this.buttonTarget.classList.toggle('expanded');
    this.filtersTarget.classList.toggle('expanded');
    this.resultsTarget.classList.toggle('expanded');

    document.body.classList.toggle('mobile-scroll-locked');
  }
}
