import bootstrapValidate from 'bootstrap-validate';

// Use bootstrap-validate to check field values
// https://bootstrap-validate.js.org/

const FIELDS_TO_VALIDATE = [
  // Suppiler business details form
  {
    selector: '#supplier_email',
    validation: 'email: Please enter a valid email address'
  },
  {
    selector: '#space_email',
    validation: 'email: Please enter a valid email address'
  },
  {
    selector: '#embedded_user_email',
    validation: 'email: Please enter a valid email address'
  },
  {
    selector: '#supplier_social_links_attributes_0_url',
    validation: 'url: Please enter a valid url'
  },
  {
    selector: '#supplier_phone_number',
    validation: 'numeric: Please enter a valid number'
  },
  {
    selector: '#subscription-footer-email',
    validation: 'email: Please enter a valid email'
  },
  {
    selector: '#availability_notifier_email',
    validation: 'email: Please enter a valid email'
  }
];

let REQUIRED_FIELDS;

export default function init() {
  $(document).on('turbolinks:load', () => {
    initValidation();
  });

  initValidation();

  $(document).on('turbolinks:before-cache', () => {
    if (REQUIRED_FIELDS) {
      removeFieldValidation([...REQUIRED_FIELDS]);
    }
  });

  $(document).on('cocoon:after-insert', (event, insertedItem) => {
    const addedFields = [
      ...insertedItem[0].querySelectorAll('.form-control.required')
    ];

    if (!addedFields) {
      return;
    }

    const requiredFieldIds = addedFields.map(field => {
      return '#' + field.id;
    });

    requiredFieldIds.forEach(field => {
      const fieldDataName = $(field).attr('data-name'),
        fieldName = fieldDataName ? fieldDataName : 'This';
      bootstrapValidate(field, `required: ${fieldName} is a required field`);
      document.querySelector(field).addEventListener('blur', handleInputChange);
    });
  });
}

function initValidation() {
  // A general solution for prompting required fields
  REQUIRED_FIELDS = document.querySelectorAll(
    '.y-validate-fields .form-control.required'
  );

  if (REQUIRED_FIELDS) {
    validateRequiredFields([...REQUIRED_FIELDS]);
  }

  // Per field validation

  for (const field of FIELDS_TO_VALIDATE) {
    if (document.querySelector(field.selector)) {
      bootstrapValidate(field.selector, field.validation);
    }
  }
}

function validateRequiredFields(fields) {
  const requiredFieldIds = fields.map(field => {
    return '#' + field.id;
  });

  // Trigger the change event on blur for required fields
  requiredFieldIds.forEach(field => {
    const fieldDataName = $(field).attr('data-validation-name'),
      fieldName = fieldDataName ? fieldDataName : 'This';

    bootstrapValidate(
      field,
      `regex:[a-zA-Z0-9]: ${fieldName} is a required field`
    );

    document.querySelector(field).addEventListener('blur', handleInputChange);
  });
}

function removeFieldValidation(fields) {
  const requiredFieldIds = fields.map(field => {
    return '#' + field.id;
  });

  // Remove required field event listeners
  requiredFieldIds.forEach(field => {
    document
      .querySelector(field)
      .removeEventListener('blur', handleInputChange);
    // document.querySelector(field).removeEventListener('input');
  });

  // Remove per field event listeners
  for (const field of FIELDS_TO_VALIDATE) {
    if (document.querySelector(field.selector)) {
      // TODO remove event listeners set by bootstrap-validate
      // document.querySelector(field.selector).removeEventLister('input');
    }
  }
}

function handleInputChange(event) {
  event.target.dispatchEvent(new Event('input'));
}
