import { Controller } from 'stimulus';

// There are known issues with Turbolinks and navigating to named anchors
// https://github.com/turbolinks/turbolinks/issues/75
// https://github.com/turbolinks/turbolinks/issues/214
// This is a basic way for us to create stimulus targets in place of named anchors

// The controller sits on the body tag, which will allow targets to be anywhere in the view
// Just be aware that if there are multiple smoothscroll targets (smooth-scroll.anchor)
// the page will scroll to the first one it encounters.

export default class extends Controller {
  static targets = ['anchor', 'radioToSelect'];

  initialize() {
    const isPreview = document.documentElement.hasAttribute(
      'data-turbolinks-preview'
    );

    if (this.hasAnchorTarget && !isPreview) {
      document.documentElement.classList.add('_y-smooth-scroll');
      this.anchorTarget.scrollIntoView();

      const message = this.anchorTarget.dataset.smoothscrollMessage;

      if (message && message != '') {
        this.anchorTarget.querySelector(
          '.js-smoothscroll-message'
        ).innerHTML = `<div class="y-notification y-notification--flash mb-4">${message}</div>`;
      }

      const radioToSelect =
        this.anchorTarget.dataset.smoothscrollRadioToSelect == 'true';

      if (radioToSelect && this.hasRadioToSelectTarget) {
        this.radioToSelectTarget.checked = 'checked';
      }
    }
  }
  disconnect() {
    document.documentElement.classList.remove('_y-smooth-scroll');
  }
}
