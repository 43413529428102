function initCustomFileSelect() {
  function updateSelectInputs(elements) {
    let fileInput = $(elements);

    let fileName = fileInput
      .val()
      .split('\\')
      .pop();

    fileInput
      .closest('.custom-file')
      .find('.custom-file-label')
      .addClass('selected')
      .html(fileName);
  }

  let $customFileInputs = $('.custom-file-input');

  // Update custom file inputs when file selected
  $customFileInputs.on('change', function() {
    updateSelectInputs(this);
  });

  // When more inputs are added using cocoon,
  // we need to reattach the change events
  $(document).on('cocoon:after-insert', function() {
    $customFileInputs.off();

    $('.custom-file-input').on('change', function() {
      updateSelectInputs(this);
      $customFileInputs = $(this);
    });
  });
}

document.addEventListener('turbolinks:load', () => {
  initCustomFileSelect();
});

initCustomFileSelect();
