import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['locations', 'email', 'toggler', 'submit']

  emailRegex = new RegExp('.+\@.+\..+')

  connect() {
    this.update()
  }

  update() {
    if (!this.enabled()) {
      this.submitTarget.disabled = false
      return
    }

    const valid = this.validEmail() && this.validLocations()

    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = !valid
    }
  }

  enabled() {
    return !this.hasTogglerTarget || this.togglerTarget.checked
  }

  validEmail() {
    if (!this.hasEmailTarget) { return true }

    return this.emailRegex.test(this.emailTarget.value.trim())
  }

  validLocations() {
    return !!this.locationsTarget.querySelector('input:checked')
  }
}
