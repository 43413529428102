import Rails from 'rails-ujs';
require('jquery-ui/ui/widget');
require('jquery-ui/ui/widgets/sortable');

$(document).on('turbolinks:load initializeSortable', () => {
  $('.js-sortable').sortable({
    handle: '.handle',
    items: '> .js-sortable-item',
    forcePlaceholderSize: true,
    start: function(event, ui) {
      ui.placeholder.html("<td colspan='2'>&nbsp;</td>");
      ui.placeholder.css('visibility', 'visible');
    },
    update: function() {
      Rails.ajax({
        url: $(this).data('url'),
        type: 'PATCH',
        data: $(this).sortable('serialize')
      });
    }
  });
});
