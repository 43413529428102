import { BaseController } from 'stimulus-library';

const STRIPE_SCRIPT_URL = 'https://js.stripe.com/v3/';

export default class extends BaseController {
  static targets = [
    'form',
    'cardNumber',
    'cardExpiry',
    'cardCvc',
    'name',
    'city',
    'country',
    'line1',
    'line2',
    'postalCode',
    'phoneNumber',
    'brandIcon',
    'submitButton',
    'errors',
    'savedCard',
    'validationErrorModal'
  ];

  connect() {
    (async () => {
      const Stripe = await this.loadStripe();

      this.stripe = Stripe(this.data.get('publishable-key'));

      const elements = this.stripe.elements({
        fonts: [
          {
            cssSrc: 'https://use.typekit.net/yzt2kuu.css'
          }
        ]
      });

      const style = {
        base: {
          color: '#495057',
          fontFamily: '"neue-haas-unica", Helvetica Neue, arial, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          lineHeight: '2'
        },
        invalid: {
          color: '#cc3535'
        }
      };

      this.cardNumber = elements.create('cardNumber', {
        style: style,
        placeholder: '1234 1234 1234 1234'
      });
      this.cardNumber.mount(this.cardNumberTarget);

      this.cardExpiry = elements.create('cardExpiry', {
        style: style,
        placeholder: 'MM/YY'
      });
      this.cardExpiry.mount(this.cardExpiryTarget);

      this.cardCvc = elements.create('cardCvc', {
        style: style,
        placeholder: 'CVC'
      });
      this.cardCvc.mount(this.cardCvcTarget);

      this.cardNumber.addEventListener('change', event => {
        // Switch brand logo
        if (event.brand) {
          this.setBrandIcon(event.brand);
        }
      });

      // Handle real-time validation errors
      [this.cardNumber, this.cardExpiry, this.cardCvc].forEach(
        (element, ind) => {
          element.on('change', function(event) {
            var displayError = $('.form-error-field');

            if (event.error) {
              $(displayError[ind]).text(event.error.message);
            } else {
              displayError.empty();
            }
          });
        }
      );
    })();
  }

  loadStripe() {
    if (window.Stripe) {
      return window.Stripe;
    }

    return new Promise((resolve, reject) => {
      var js = document.createElement('script');
      js.src = STRIPE_SCRIPT_URL;
      js.onload = function() {
        resolve(window.Stripe);
      };
      js.onerror = function() {
        reject(new Error('Failed to load script ' + STRIPE_SCRIPT_URL));
      };
      document.head.appendChild(js);
    });
  }

  get paymentMethod() {
    if (this.savedPaymentMethodId) {
      return {
        payment_method: this.savedPaymentMethodId
      };
    } else {
      return {
        payment_method: {
          card: this.cardNumber,
          billing_details: {
            name: this.nameTarget.value,
            address: {
              line1: this.line1Target.value,
              line2: this.line2Target.value,
              city: this.cityTarget.value,
              postal_code: this.postalCodeTarget.value,
              country: this.countryTarget.value
            }
          }
        },
        setup_future_usage: 'off_session'
      };
    }
  }

  get savedPaymentMethod() {
    return this.savedCardTargets.find(target => target.checked);
  }

  get savedPaymentMethodId() {
    return this.savedPaymentMethod && this.savedPaymentMethod.value;
  }

  get validationController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      'validation'
    );
  }

  submit(event) {
    if (this.submitButtonTarget.dataset.freeBasket != 'true') {
      event.preventDefault();

      if (this.validationController.formIsValid) {
        this.loading(true);

        $.ajax({
          url: this.data.get('validate-basket-path'),
          type: 'PUT',
          dataType: 'json',
          data: {
            address: {
              line_1: this.line1Target.value,
              line_2: this.line2Target.value,
              city: this.cityTarget.value,
              postal_code: this.postalCodeTarget.value,
              country: this.countryTarget.value,
              phone_number: this.phoneNumberTarget.value
            },
            payment_method_id: this.savedPaymentMethodId
          },
          success: () => {
            this.confirmCardPayment();
          },
          error: data => {
            if (data.responseJSON.errors) {
              this.displayError(data.responseJSON.errors.join(', '));
              this.loading(false);
            } else {
              $(this.validationErrorModalTarget).modal('show');
            }
          }
        });
      }
    }
  }

  confirmCardPayment() {
    this.stripe
      .confirmCardPayment(this.data.get('client-secret'), this.paymentMethod)
      .then(result => {
        if (result.error) {
          // Show error to your customer
          this.displayError(result.error.message);
        } else {
          // The payment succeeded!
          this.formTarget.submit();
        }

        return;
      })
      .catch(function() {});
  }

  displayError(error = null) {
    this.loading(false);

    this.errorsTarget.innerHTML =
      error ||
      'We’re sorry but your payment was unsuccessful, please check your card details or contact your bank';
  }

  loading(isLoading) {
    if (isLoading) {
      this.submitButtonTarget.disabled = true;
    } else {
      this.submitButtonTarget.disabled = false;
    }
  }

  setBrandIcon(brand) {
    const cardBrandToId = {
      visa: 'icon-cc-visa',
      mastercard: 'icon-cc-mastercard',
      amex: 'icon-cc-amex',
      discover: 'icon-cc-discover',
      diners: 'icon-cc-diners',
      jcb: 'icon-cc-jcb',
      unknown: 'icon-cc-card'
    };

    if (brand in cardBrandToId) {
      this.brandIconTarget.setAttribute(
        'xlink:href',
        `#${cardBrandToId[brand]}`
      );
    }
  }
}
