import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['emailExplanation'];

  toggleExplanation(event) {

    // Toggle text area
    if(event.currentTarget.checked)
    {
      $(this.emailExplanationTarget).collapse('toggle').show();
    }
    else
    {
      $(this.emailExplanationTarget).collapse('toggle').hide();
    }
  }
}
