import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'hideableInput',
    'submit',
    'disabledInput',
    'disabledInputSwitch',
    'indicator'
  ];

  initialize() {
    // If there's a section that depends on a question being
    // answered first show those sections as disabled
    // This was initially built for the customer review page

    if (this.hasDisabledInputTarget && this.hasDisabledInputSwitchTarget) {
      const inputIsSelected = document.querySelector(
        `input[name="${this.disabledInputSwitchTarget.name}"]:checked`
      );

      if (!inputIsSelected) {
        const disabledInputs = [...this.disabledInputTargets];
        for (const input of disabledInputs) {
          input.disabled = true;
        }
      } else {
        this.toggleHideableInputs(null, inputIsSelected.value);
      }
    }
  }

  submitState() {
    const submitButton = this.submitTarget;
    const submitText = submitButton.dataset.submitText;
    if (submitText)
      submitButton.querySelector('.y-submit__text').innerText = submitText;
    submitButton.classList.add('y-submit--loading');
  }

  disableSubmitIfEmpty(event) {
    if (event.currentTarget.value) {
      this.submitTarget.removeAttribute('disabled');
    } else {
      this.submitTarget.setAttribute('disabled', true);
    }
  }

  toggleHideableInputs(event, value) {
    const currentValue = event ? event.currentTarget.value : value;

    this.hideableInputTargets.forEach(input => {
      const showOn = JSON.parse(input.dataset.showOn),
        wrapper = input.closest('.js-input-wrapper'),
        isToggle = wrapper.classList.contains('y-check--toggle');

      if (showOn.includes(currentValue)) {
        input.disabled = false;
        // If a toggle element, use inline-block
        wrapper.style.display = isToggle ? 'inline-block' : 'block';
      } else {
        input.closest('.js-input-wrapper').style.display = 'none';
        input.disabled = true;
      }

      // Deselect any previously selected toggles
      if (!value && isToggle) {
        input.checked = false;
      }
    });
  }

  d;

  enableInputs() {
    const disabledInputs = [...this.disabledInputTargets];
    for (const input of disabledInputs) {
      input.disabled = false;
    }

    if (this.hasIndicatorTarget) {
      this.indicatorTarget.classList.add('confirmed');
    }
  }
}
