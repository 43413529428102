import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'paypal',
    'bank',
    'manual',
    'choice',
    'account',
    'updateForm',
    'updateButton'
  ];
  connect() {
    console.log('payout is connected');
    if (this.accountTarget.value) {
      var update_button = document.getElementById('update-button');
      update_button.innerText = 'Cancel update';
      this.showForm();
    }
  }

  showUpdateForm = false;

  initialize() {
    this.renderUI(this.choiceTarget.value);
    this.updateFormTarget.style.display = 'none';
  }

  toggle(e) {
    const selection = e.target.value;
    this.renderUI(selection);
  }

  showForm(e) {
    this.showUpdateForm = !this.showUpdateForm;
    this.showUpdateForm
      ? (this.updateFormTarget.style.display = 'block')
      : (this.updateFormTarget.style.display = 'none');
    e.target.innerText = this.showUpdateForm
      ? 'Cancel update'
      : 'Update account';
  }

  renderUI(selection) {
    if (selection == 'paypal') {
      this.paypalTarget.style.display = 'block';
      this.bankTarget.style.display = 'none';
      this.manualTarget.style.display = 'none';
    } else if (selection == 'bank_transfer') {
      this.paypalTarget.style.display = 'none';
      this.bankTarget.style.display = 'block';
      this.manualTarget.style.display = 'none';
    } else if (selection == 'manual') {
      this.paypalTarget.style.display = 'none';
      this.bankTarget.style.display = 'none';
      this.manualTarget.style.display = 'block';
    } else {
      this.paypalTarget.style.display = 'none';
      this.bankTarget.style.display = 'none';
      this.manualTarget.style.display = 'none';
    }
  }
}
