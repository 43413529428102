import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['count', 'input'];

  initialize() {
    this.characterLimit = this.countTarget.dataset.maxDescriptionChars;
    this.countTarget.innerText = this.characterLimit;
    this.handleCharacterCount(this.inputTarget.value);
  }

  handleCharacterCount() {
    const remainingCharacters =
      this.characterLimit - this.inputTarget.value.length;
    this.countTarget.innerText = remainingCharacters;
    if (remainingCharacters < 0) {
      this.countTarget.parentNode.classList.add('text-danger');
    } else {
      this.countTarget.parentNode.classList.remove('text-danger');
    }
  }
}
