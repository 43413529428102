import { Controller } from 'stimulus';
const SimpleLightbox = require('simple-lightbox');
SimpleLightbox.registerAsJqueryPlugin($);

let LIGHTBOX_IS_APPLICABLE;
let CAROUSEL_INITIATED = false;
let SCREEN_QUERY = window.matchMedia('(min-width: 768px)');

export default class extends Controller {
  static targets = ['placeholder'];

  initialize() {
    const app = this;

    const isPreview = document.documentElement.hasAttribute(
      'data-turbolinks-preview'
    );

    screenChange(app);

    if (!isPreview) {
      SCREEN_QUERY.addListener(() => {
        screenChange(app);
      });
    }
  }

  setupCarousel() {
    if (!CAROUSEL_INITIATED) {
      const placeholders = this.placeholderTargets;

      placeholders.forEach((placeholder, index) => {
        const image = new Image();
        image.src = placeholders[index].dataset.large;
        placeholder.appendChild(image);
      });

      CAROUSEL_INITIATED = true;
    }
  }

  showLightbox() {
    // We have to force the hero image to be the
    // first image in the lightbox.

    if (LIGHTBOX_IS_APPLICABLE) {
      const $experienceGallery = $('.y-experience-gallery'),
        $experienceGalleryImages = $experienceGallery
          .find('.y-experience-gallery__lightbox-element')
          .toArray(),
        $experienceGalleryHeroImage = $experienceGallery
          .find('.y-experience-gallery__item--hero')[0]
          .querySelector('img');

      // Push the hero image to the front of the array
      $experienceGalleryImages.unshift($experienceGalleryHeroImage);

      const lightboxImageArray = $experienceGalleryImages.map(galleryItem => {
        return galleryItem.dataset.lightboxImage;
      });

      SimpleLightbox.open({
        items: lightboxImageArray
      });
    }
  }

  disconnect() {
    CAROUSEL_INITIATED = false;
  }
}

function screenChange(app) {
  if (SCREEN_QUERY.matches) {
    LIGHTBOX_IS_APPLICABLE = true;
  } else {
    LIGHTBOX_IS_APPLICABLE = false;
    app.setupCarousel();
  }
}
