$(document).on('turbolinks:load', () => {
  $('a.js-auth-facebook').click(function(e) {
    e.preventDefault();
    FB.login(function(response) {
      console.log(response);
      if (response.authResponse) {
        // since we have cookies enabled, this request will allow omniauth to parse
        // out the auth code from the signed request in the fbsr_XXX cookie
        //params = $.param({code: response.authResponse.signedRequest})
        window.location = "/users/auth/facebook/callback";

        //$.post(
          //'/users/auth/facebook/callback'
          ////function(json) {
            ////location.reload();
          ////}
        //);
      }
    }, {scope: 'email'} ); // if you want custom scopes, pass them as an extra, final argument to FB.login
  });
});
