import { Controller } from "stimulus"

export default class extends Controller {
  POSITION_INPUT = "input[name$='[position]']"

  static values = {
    wrapper: String,
  }

  up(event) {
    const current = this.currentPositionInput(event)
    const prev = this.positionInput(Number(current.value) - 1)
    if (!prev) return // already at the top

    this.swapElements(current, prev)
  }

  down(event) {
    const current = this.currentPositionInput(event)
    const next = this.positionInput(Number(current.value) + 1)
    if (!next) return // already at the bottom

    this.swapElements(current, next)
  }


  currentPositionInput(event) {
    return event.target.closest(this.wrapperValue).querySelector(this.POSITION_INPUT)
  }

  positionInput(i) {
    const query = this.POSITION_INPUT + `[value='${i}']`
    return this.element.querySelector(query)
  }

  swapElements(a, b) {
    // swap the position values
    const temp = a.value
    a.setAttribute("value", b.value)
    b.setAttribute("value", temp)

    // swap the elements in the dom
    const aParent = a.closest(this.wrapperValue)
    const bParent = b.closest(this.wrapperValue)
    const currentOrder = aParent.compareDocumentPosition(bParent)
    switch(currentOrder) {
      case Node.DOCUMENT_POSITION_FOLLOWING:
        aParent.before(bParent)
        break
      case Node.DOCUMENT_POSITION_PRECEDING:
        bParent.before(aParent)
        break
    }
  }
} 
