import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'startTime', 'suggestedStartTime'];

  initialize() {
    console.log('adminBookingState controller connected');
  }

  toggleAllowedAttributes() {
    switch (this.selectTarget.value) {
      case 'opened':
        this.startTimeTarget.disabled = true;
        this.suggestedStartTimeTarget.disabled = true;
        break;
      case 'waiting_for_confirmation':
        this.startTimeTarget.disabled = false;
        this.suggestedStartTimeTarget.disabled = true;
        break;
      case 'confirmed':
        this.startTimeTarget.disabled = false;
        this.suggestedStartTimeTarget.disabled = true;
        break;
      case 'rejected':
        this.startTimeTarget.disabled = false;
        this.suggestedStartTimeTarget.disabled = true;
        break;
      case 'cancelled':
        this.startTimeTarget.disabled = true;
        this.suggestedStartTimeTarget.disabled = true;
        break;
      case 'completed':
        this.startTimeTarget.disabled = false;
        this.suggestedStartTimeTarget.disabled = true;
        break;
      case 'time_slot_suggested':
        this.startTimeTarget.disabled = false;
        this.suggestedStartTimeTarget.disabled = false;
        break;
    }
  }
}
