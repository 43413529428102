import { Controller } from 'stimulus';

// This controller exists to catch when messages are added/removed
// from the DOM when switching messages. connect() will fire a custom event
// that gets caught by an action inside inbox_controller.js.

// The below is a roundabout way to communicate between controllers in Stimulus
// https://github.com/stimulusjs/stimulus/issues/200

export default class extends Controller {
  connect() {
    const event = document.createEvent('CustomEvent');
    event.initCustomEvent('messages-connected', true, true, null);
    this.element.dispatchEvent(event);
  }
}
