import { Controller } from "stimulus";
import debounce from 'lodash/debounce';

export default class extends Controller {
  static values = { name: String, sponsors: Array }

  connect() {
    this.state = Object.entries(
      this.sponsorsValue.reduce((acc, sponsor) => {
        const [id, content_json, file_url] = sponsor

        if (content_json.group in acc) {
          acc[content_json.group].push(sponsor)
        } else {
          acc[content_json.group] = [sponsor]
        }
        return acc
      }, {})
    )
    this.deleted = []

    this.updateGroup = debounce(this.updateGroup, 250).bind(this);

    this.view()
  }

  randomId() {
    const randomId = `[${Math.floor(Math.random() * 100000)}]`
    
    return randomId
  }

  createElement(e, f = () => { }) {
    let el = document.createElement(e)
    let append = (...args) => { el.appendChild(this.createElement(...args)) }
    f(el, append)
    return el
  }

  tag(t, ...args) {
    t.appendChild(this.createElement(...args))
  }

  groupIndex(event) {
    const groupNode = event.target.closest("[data-group]")
    const index = Array.from(groupNode.parentNode.querySelectorAll("[data-group]")).indexOf(groupNode)
    return index
  }

  sponsorIndex(event) {
    const groupNode = event.target.closest("[data-group]")
    const index = Array.from(groupNode.parentNode.querySelectorAll("[data-group]")).indexOf(groupNode)
    const sponsorIndex = Array.from(groupNode.querySelectorAll("[data-sponsor]")).indexOf(event.target.closest("[data-sponsor]"))
    return sponsorIndex
  }

  view() {
    const groups = this.createElement("div", (el, tag) => {
      this.state.forEach((group, i) => {
        el.appendChild(this.viewGroup(group, i))
      })

      tag("a", (el, tag) => {
        el.textContent = "Add group"
        el.classList = "btn btn-primary my-3"
        el.dataset.action = "admin-sponsor#addGroup"
      })
    })

    this.element.replaceChildren(this.viewDeleted(), groups)
  }

  viewDeleted() {
    return this.createElement("div", (el, tag) => {
      this.deleted.forEach(id => {
        const randomId = this.randomId()
        tag("input", el => {
          el.type = "hidden"
          el.name = this.nameValue + randomId + "[id]"
          el.value = id
        })
        tag("input", el => {
          el.type = "hidden"
          el.name = this.nameValue + randomId + ["_destroy"]
          el.value = "1"
        })
      })
    })
  }

  viewGroup(group, i) {
    const [groupName, groupSponsors] = group
    return this.createElement("div", (el, tag) => {
      el.dataset.group = ""

      tag("input", el => {
        el.classList = "form-control"
        el.dataset.action = "keyup->admin-sponsor#updateGroup"
        el.value = groupName
        el.dataset.groupMainInput = ""
      })

      tag("a", el => {
        el.textContent = "Add sponsor"
        el.classList = "btn btn-primary my-3"
        el.dataset.action = "admin-sponsor#addSponsor"
      })

      tag("a", el => {
        el.textContent = "Remove group"
        el.classList = "btn btn-secondary my-3 ml-2"
        el.dataset.action = "admin-sponsor#removeGroup"
      })
      tag("a", el => {
        el.textContent = "Move up"
        el.classList = "btn btn-primary my-3 ml-2"
        el.dataset.action = "admin-sponsor#moveUpGroup"
      })
      tag("a", el => {
        el.textContent = "Move down"
        el.classList = "btn btn-primary my-3 ml-2"
        el.dataset.action = "admin-sponsor#moveDownGroup"
      })

      groupSponsors.forEach((sponsor, j) => {
        el.appendChild(this.viewSponsor(sponsor, i, j))
      })

      tag("hr")
    })
  }

  viewSponsor(sponsor, i, j) {
    const [id, content_json, file_url] = sponsor 


    return this.createElement("div", (el, tag) => {
      el.classList = "px-4"
      el.dataset.sponsor = ""

      const randomId = this.randomId()

      // update if it already exists
      if (id !== null) {
        tag("input", el => {
          el.type = "hidden"
          el.name = this.nameValue + randomId + "[id]"
          el.value = id
        })
      }

      tag("input", el => {
        el.type = "hidden"
        el.name = this.nameValue + randomId + "[content_json][position]"
        // big offset to make sure acts_as_list is happy, better
        // solution is to create generated column from group and
        // add it to scope, but that requires PG 16(?)
        el.value = i * 1000 + j
      })

      // the image and preview
      tag("label", (el, tag) => {
        el.textContent = "Image"
      })
      tag("div", (el, tag) => {
        el.classList = "custom-file h-25"

        // restore the cached input if we have one
        if ("cached_file_input" in content_json) {
          content_json.cached_file_input.name = this.nameValue + randomId + "[file]"
          el.appendChild(content_json.cached_file_input)
        } else {
          tag("img", (el, tag) => {
            el.src = file_url
            el.classList = "img-fluid mb-2"
          })
          tag("input", (el, tag) => {
            el.type = "file"
            el.name = this.nameValue + randomId + "[file]"
            el.dataset.action = "admin-sponsor#updateSponsor"
            el.dataset.fileInput = ""
          })
        }
      })

      // the metadata fields
      tag("label", el => {
        el.textContent = "Link"
        el.classList = "mt-2"
      })
      tag("input", (el, tag) => {
        el.name = this.nameValue + randomId + "[content_json][link]"
        el.classList = "form-control"
        el.dataset.linkInput = ""
        el.dataset.action = "admin-sponsor#updateSponsor"
        el.value = content_json.link
      })
      tag("input", (el, tag) => {
        el.type = "hidden"
        el.name = this.nameValue + randomId + "[content_json][group]"
        el.dataset.groupInput = ""
        el.value = content_json.group
      })

      tag("a", el => {
        el.textContent = "Remove sponsor"
        el.classList = "btn btn-secondary my-3"
        el.dataset.action = "admin-sponsor#removeSponsor"
      })
      tag("a", el => {
        el.textContent = "Move up"
        el.classList = "btn btn-primary my-3 mx-2"
        el.dataset.action = "admin-sponsor#moveUpSponsor"
      })
      tag("a", el => {
        el.textContent = "Move down"
        el.classList = "btn btn-primary my-3"
        el.dataset.action = "admin-sponsor#moveDownSponsor"
      })
    })
  }

  addGroup(event) {
    this.state.push(["", []])
    this.view()
  }

  updateGroup(event) {
    const index = this.groupIndex(event)

    this.state[index][0] = event.target.value
    this.state[index][1].forEach(sponsor => {
      sponsor[1].group = event.target.value
    })
    this.view()
    Array.from(this.element.querySelectorAll("[data-group-main-input]"))[index].focus()
  }

  removeGroup(event) {
    const index = this.groupIndex(event)

    const groupToRemove = this.state[index]
    this.state = this.state.filter((x, i) => i !== index)
    groupToRemove[1].forEach(sponsor => {
      const [id, content_json, file_url] = sponsor
      if (id !== null) {
        this.deleted.push(id)
      }
    })
    this.view()
  }

  moveUpGroup(event) {
    const index = this.groupIndex(event)
    if (index === 0) return

    const temp = this.state[index - 1]
    this.state[index - 1] = this.state[index]
    this.state[index] = temp
    this.view()
  }

  moveDownGroup(event) {
    const index = this.groupIndex(event)
    if (index === this.state.length - 1) return

    const temp = this.state[index + 1]
    this.state[index + 1] = this.state[index]
    this.state[index] = temp
    this.view()
  }

  addSponsor(event) {
    const index = this.groupIndex(event)

    this.state[index][1].push([null, { link: "", group: this.state[index][0] }, ""])
    this.view()
  }

  // we cache the file input node in the state so we can remember
  // the file that was uploaded after a render
  updateSponsor(event) {
    const index = this.groupIndex(event)
    const sponsorIndex = this.sponsorIndex(event)

    if (event.target.type == "file") {
      this.state[index][1][sponsorIndex][1].cached_file_input = event.target.cloneNode(true)
    } else {
      this.state[index][1][sponsorIndex][1].link = event.target.value
    }
  }

  removeSponsor(event) {
    const index = this.groupIndex(event)
    const sponsorIndex = this.sponsorIndex(event)

    const [id, content_json, file_url] = this.state[index][1][sponsorIndex]
    if (id !== null) {
      this.deleted.push(id)
    }
    this.state[index][1] = this.state[index][1].filter((x, i) => i !== sponsorIndex )
    this.view()
  }

  moveUpSponsor(event) {
    const index = this.groupIndex(event)
    const sponsorIndex = this.sponsorIndex(event)
    if (sponsorIndex === 0) return

    const temp = this.state[index][1][sponsorIndex - 1]
    this.state[index][1][sponsorIndex - 1] = this.state[index][1][sponsorIndex]
    this.state[index][1][sponsorIndex] = temp

    this.view()
  }

  moveDownSponsor(event) {
    const index = this.groupIndex(event)
    const sponsorIndex = this.sponsorIndex(event)
    if (sponsorIndex === this.state[index][1].length - 1) return

    const temp = this.state[index][1][sponsorIndex + 1]
    this.state[index][1][sponsorIndex + 1] = this.state[index][1][sponsorIndex]
    this.state[index][1][sponsorIndex] = temp

    this.view()
  }
}
