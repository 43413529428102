// originally faq groupings were implemented as a seperate table, but now we
// just use nested json to better fit the existing CMS pattern. this controller
// is for dynamically adding/removing the new faq groups and entries.

import { Controller } from "stimulus"

// state = [{ title: "", entries: [] }]
export default class extends Controller {
  static values = { name: String, groups: Array }

  connect() {
    this.view()
  }

  createElement(e, f = () => { }) {
    let el = document.createElement(e)
    f(el)
    return el
  }

  groupIndex(event) {
    const groups = Array.from(this.element.querySelectorAll("[data-group]"))
    const groupToRemove = event.target.closest("[data-group]")
    const index = groups.findIndex(el => el == groupToRemove)

    return index
  }

  entryIndex(event, groupIndex) {
    const entries = Array.from(this.element.querySelectorAll(`[data-group]:nth-of-type(${groupIndex + 1}) [data-entry]`))
    const entryToRemove = event.target.closest("[data-entry]")
    const index = entries.findIndex(el => el == entryToRemove)

    return index
  }

  randomId() {
    return Math.random().toString(16).slice(2)
  }

  view() {
    const hiddenInput = this.createElement("input", el => { el.name = this.nameValue, el.type = "hidden"; el.value = JSON.stringify(this.groupsValue) })
    const newChildren = []
    this.groupsValue.forEach(group => { newChildren.push(this.viewGroup(group)) })
    const addGroup = this.createElement("a", el => { el.textContent = "Add group"; el.classList = "btn btn-primary my-3"; el.dataset.action = "admin-faq#addGroup" })
    this.element.replaceChildren(hiddenInput, ...newChildren, addGroup)
  }

  viewGroup(group) {
    return this.createElement("div", el => {
      el.dataset.group = ""
      el.appendChild(this.createElement("input", el => { el.value = group.title; el.classList = "form-control"; el.dataset.action = "admin-faq#updateGroup" }))
      el.appendChild(this.createElement("a", el => { el.textContent = "Add entry"; el.classList = "btn btn-primary my-3"; el.dataset.action = "admin-faq#addEntry" }))
      el.appendChild(this.createElement("a", el => { el.textContent = "Remove group"; el.classList = "btn btn-secondary ml-2 my-3"; el.dataset.action = "admin-faq#removeGroup" }))
      el.appendChild(this.createElement("a", el => { el.textContent = "Move up"; el.classList = "btn btn-primary ml-2 my-3"; el.dataset.action = "admin-faq#moveUpGroup"}))
      el.appendChild(this.createElement("a", el => { el.textContent = "Move down"; el.classList = "btn btn-primary ml-2 my-3"; el.dataset.action = "admin-faq#moveDownGroup"}))
      group.entries.forEach(entry => { el.appendChild(this.viewEntry(entry)) })
    })
  }

  viewEntry(entry) {
    return this.createElement("div", el => {
      el.dataset.entry = ""
      el.classList = "mt-3 px-4"

      // QUESTION
      const questionId = this.randomId()
      el.appendChild(this.createElement("label", el => { el.textContent = "Question" }))
      el.appendChild(this.createElement("input", el => {
        el.id = questionId
        el.value = entry.q
        el.classList = "form-control mb-2"
        el.dataset.action = "keyup->admin-faq#updateEntry"
        el.dataset.question = ""
      }))

      // ANSWER
      const answerId = this.randomId()
      el.appendChild(this.createElement("label", el => { el.textContent = "Answer" }))
      el.appendChild(this.createElement("input", el => {
        el.id = answerId
        el.type = "hidden"
        el.value = entry.a
        el.classList = "form-control mb-2"
        el.dataset.answer = ""
      }))
      el.appendChild(this.createElement("trix-editor", el => {
        el.setAttribute("input", answerId)
        el.dataset.action = "trix-change->admin-faq#updateEntry"
      }))

      el.appendChild(this.createElement("a", el => { el.textContent = "Remove entry"; el.classList = "btn btn-secondary my-3" ; el.dataset.action = "admin-faq#removeEntry" }))
      el.appendChild(this.createElement("a", el => { el.textContent = "Move up"; el.classList = "btn btn-primary my-3 mx-2" ; el.dataset.action = "admin-faq#moveUpEntry" }))
      el.appendChild(this.createElement("a", el => { el.textContent = "Move down"; el.classList = "btn btn-primary my-3" ; el.dataset.action = "admin-faq#moveDownEntry" }))

      el.appendChild(this.createElement("hr"))
    })
  }

  addGroup() {
    this.groupsValue = this.groupsValue.concat([{ title: "", entries: [] }])
    this.view()
  }

  removeGroup(event) {
    const index = this.groupIndex(event)
    this.groupsValue = this.groupsValue.filter((_group, i) => i !== index)
    this.view()
  }

  updateGroup(event) {
    const index = this.groupIndex(event)

    this.groupsValue = this.groupsValue.map((group, i) => {
      if (i == index) {
        group.title = event.target.value
      }
      return group
    })
    this.element.querySelector("input[type='hidden']").value = JSON.stringify(this.groupsValue)

  }

  moveUpGroup(event) {
    const index = this.groupIndex(event)
    if (index === 0) return

    const newVal = JSON.parse(JSON.stringify(this.groupsValue))
    const temp = this.groupsValue[index - 1]
    newVal[index - 1] = this.groupsValue[index]
    newVal[index] = temp
    this.groupsValue = newVal
    this.view()
  }

  moveDownGroup(event) {
    const index = this.groupIndex(event)
    if (index == this.groupsValue.length - 1) return

    const newVal = JSON.parse(JSON.stringify(this.groupsValue))
    const temp = this.groupsValue[index + 1]
    newVal[index + 1] = this.groupsValue[index]
    newVal[index] = temp
    this.groupsValue = newVal
    this.view()
  }

  addEntry(event) {
    const index = this.groupIndex(event)

    this.groupsValue = this.groupsValue.map((group, i) => {
      if (i == index) {
        group.entries = group.entries.concat([{ q: "", a: "" }])
      }
      return group
    })
    this.view()
  }

  removeEntry(event) {
    const index = this.groupIndex(event)
    const entryIndex = this.entryIndex(event, index)

    this.groupsValue = this.groupsValue.map((group, i) => {
      if (i == index) {
        group.entries = group.entries.filter((_group, i) => i !== entryIndex)
      }

      return group
    })

    this.view()
  }

  moveUpEntry(event) {
    const index = this.groupIndex(event)
    const entryIndex = this.entryIndex(event, index)
    if (entryIndex === 0) return

    this.groupsValue = this.groupsValue.map((group, i) => {
      if (i == index) {
        const temp = group.entries[entryIndex - 1]
        group.entries[entryIndex - 1] = group.entries[entryIndex]
        group.entries[entryIndex] = temp 
      }

      return group
    })
    this.view()
  }

  moveDownEntry(event) {
    const index = this.groupIndex(event)
    const entryIndex = this.entryIndex(event, index)
    if (entryIndex === this.groupsValue[index].entries.length - 1) return

    this.groupsValue = this.groupsValue.map((group, i) => {
      if (i == index) {
        const temp = group.entries[entryIndex + 1]
        group.entries[entryIndex + 1] = group.entries[entryIndex]
        group.entries[entryIndex] = temp
      }

      return group
    })
    this.view()
  }

  updateEntry(event) {
    const index = this.groupIndex(event)
    const entryIndex = this.entryIndex(event, index)

    let input
    let attribute
    // hack
    if (event.type == "keyup") {
      input = event.target
      attribute = "q"
    } else {
      input = document.getElementById(event.target.getAttribute("input"))
      attribute = "a"
    }

    this.groupsValue = this.groupsValue.map((group, i) => {
      if (i == index) {
        group.entries = group.entries.map((entry, j) => {
          if (j == entryIndex) {
            entry[attribute] = input.value
          }

          return entry
        })
      }
      return group
    })
    this.element.querySelector("input[type='hidden']").value = JSON.stringify(this.groupsValue)
  }
}
