import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'other'];

  initialize() {
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  connect() {
    this.selectTarget.addEventListener('change', this.toggleVisibility);
    requestAnimationFrame(this.toggleVisibility);
  }

  toggleVisibility() {
    let notableOptions = ['Other', 'Yuup Host Referral'];
    let selectedOption = this.selectTarget.options[
      this.selectTarget.selectedIndex
    ].text;

    if (notableOptions.includes(selectedOption)) {
      let message = 'Please give details';
      switch (selectedOption) {
        case 'Other':
          message = 'Source:';
          break;
        case 'Yuup Host Referral':
          message = 'Yuup Host:';
          break;
      }
      this.showNotes(message);
    } else {
      this.hideNotes();
    }
  }

  hideNotes() {
    this.otherTarget.classList.add('_hidden');
  }

  showNotes(message) {
    this.otherTarget.querySelector('label').innerText = message;
    this.otherTarget.classList.remove('_hidden');
  }
}
