const CONFIRM_TEXT =
  'You have not saved your changes. Are you sure you want to leave this page?';
const SELECTOR = '.js-confirm-before-exit';

let willConfirmOnExit = false;

function confirmExit() {
  if (willConfirmOnExit) {
    if (confirm(CONFIRM_TEXT)) {
      willConfirmOnExit = false;
    } else {
      event.preventDefault();
    }
  }
}

function setWillConfirmOnExit() {
  const form = event.target.closest('form');

  if (form && form.matches(SELECTOR)) {
    willConfirmOnExit = true;
  }
}

//
document.addEventListener('change', setWillConfirmOnExit);
document.addEventListener('cocoon:after-insert', setWillConfirmOnExit);
document.addEventListener('cocoon:after-remove', setWillConfirmOnExit);

document.addEventListener('turbolinks:before-visit', confirmExit);

document.addEventListener('ajax:beforeSend', ({ target }) => {
  if (target.matches(SELECTOR)) {
    confirmExit();
  }
});
