import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    // We need an event listener instead of a stimulus action to detect optgroup clicks.
    this.select = this.select.bind(this);
    this.element.addEventListener('mousedown', this.select);
  }

  disconnect() {
    this.element.removeEventListener('mousedown', this.select);
  }

  select(event) {
    event.preventDefault();
    const clicked = event.target;

    if (clicked instanceof HTMLOptGroupElement) {
      this.selectGroup(clicked);
      return;
    }

    this.toggle('selected', clicked); // It's a normal option, toggle it
  }

  selectGroup(clicked) {
    const selected = clicked.hasAttribute('data-selected');

    Array.from(clicked.children, option => {
      selected
        ? option.removeAttribute('selected')
        : option.setAttribute('selected', true);
    });

    this.toggle('data-selected', clicked);
  }

  toggle(attribute, element) {
    if (element.hasAttribute(attribute)) {
      element.removeAttribute(attribute);
      return;
    }

    element.setAttribute(attribute, true);
  }
}
