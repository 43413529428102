import { Controller } from 'stimulus';
import forEach from 'lodash/forEach';

export default class extends Controller {
  static targets = ['input', 'clearButton'];

  initialize() {
    if (this.hasClearButtonTarget && this.inputTarget.value != '') {
      this.clearButtonTarget.classList.remove('d-none');
    }
  }

  clearInput() {
    this.inputTarget.value = '';
    this.inputTarget.focus();
    this.clearButtonTarget.classList.add('d-none');
  }

  handleInput(event) {
    // Debounce the event so that we don't fire
    // on every key press

    let typingTimer;
    const doneTypingInterval = 500,
      inputLength = event.target.value.length,
      button = this.clearButtonTarget;

    clearTimeout(typingTimer);
    typingTimer = setTimeout(function() {
      if (inputLength == 0) {
        button.classList.add('d-none');
        return;
      }

      button.classList.remove('d-none');
    }, doneTypingInterval);
  }

  enable() {
    forEach(this.inputTargets, input => {
      input.disabled = false;
    });
  }
}
