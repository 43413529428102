import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    var script = document.createElement('script');
    script.onload = function() {
      window.a2a.init_all();
    };
    script.src = 'https://static.addtoany.com/menu/page.js';

    document.head.appendChild(script);
  }
}
