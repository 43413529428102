import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['creditCheckBox', 'GiftcreditCheckBox'];

  toggleCredit(event) {
    Rails.ajax({
      type: 'PATCH',
      url:
        event.currentTarget.dataset.url +
        '?apply_credit=' +
        event.currentTarget.checked,
      dataType: 'json'
    });
  }
}
