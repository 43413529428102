import Rails from 'rails-ujs';
import { BaseController } from 'stimulus-library';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

export default class BookingCalendarController extends BaseController {
  static values = {
    datesAvailablePanelPath: String,
    eventsPath: String,
    datesAvailablePath: String
  };

  static targets = ['calendar', 'dates', 'sidebar'];

  static CLASS_ACTIVE_DAY = 'y-bg-event--active';

  connect() {
    this.requiredCapacity = 1
    this.activeDate = ''

    if (this.calendarTarget) {
      const today = new Date();

      this.calendar = new Calendar(this.calendarTarget, {
        plugins: [dayGridPlugin, interactionPlugin],
        eventDisplay: 'background',
        startParam: 'start_time',
        contentHeight: 'auto',
        endParam: 'end_time',
        fixedWeekCount: false,
        firstDay: 1,
        validRange: {
          start: new Date(today.setDate(1)),
          end: new Date(today.setMonth(today.getMonth() + 12, 0))
        },
        events: {
          url: this.eventsPathValue,
          extraParams: () => {
            return { required_capacity: this.requiredCapacity }
          }
        },
        eventClassNames: ({ event }) => {
          // Unfortuantly this event callback doesn't have access to the dom elements so we
          // need to fetch it manually
          const element = document.querySelector(`td[data-date='${event.startStr}']`)

          element.classList.toggle('y-available', event.extendedProps.status !== 'unavailable')
        },
        eventClick: (info) => {
          this.setActiveClassFor(info)
          this.activeDate = info.event.startStr
          this.fetchTimeSlots()
        },
      });

      this.calendar.render()
    }

  }

  setCapacity(event) {
    this.requiredCapacity = Number(event.target.value)
    this.calendar.refetchEvents()
    this.updateDatesAvailable()

    this.fetchTimeSlots()
  }

  setActiveClassFor(day) {
    this.unsetActiveDayClass()

    // Parent element should always exist
    const parentElement = day.el.closest('.fc-daygrid-day')

    parentElement.classList.add(BookingCalendarController.CLASS_ACTIVE_DAY)
  }

  unsetActiveDayClass() {
    const activeDay = document.querySelector(`.${BookingCalendarController.CLASS_ACTIVE_DAY}`)

    if (!activeDay) return

    activeDay.classList.remove(BookingCalendarController.CLASS_ACTIVE_DAY)
  }

  fetchTimeSlots() {
    Rails.ajax({
      url: `${
        this.datesAvailablePanelPathValue
      }?date=${this.activeDate}&required_capacity=${this.requiredCapacity}`,
      type: 'get',
      dataType: 'json',
      success: (data) => {
        this.sidebarTargets.forEach((sidebar) => {
          sidebar.innerHTML = data
        })
      }
    })
  }

  updateDatesAvailable() {
    Rails.ajax({
      url: `${
        this.datesAvailablePathValue
      }?required_capacity=${this.requiredCapacity}`,
      type: 'get',
      dataType: 'json',
      success: (data) => {
        // Should always be found
        const element = document.querySelector('#total-dates-available')

        element.innerText = data.total_dates_available
      }
    })
  }
}
