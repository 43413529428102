import * as noUiSlider from 'nouislider';
import * as wNumb from 'wnumb';

window.initFilters = filters => {
  const filterSlider = document.getElementById('filter-slider');

  if (filterSlider && !filterSlider.noUiSlider) {
    const $filterForm = $('#filters-form'),
      $calendarButtons = $filterForm.find('.y-calendar-button'),
      // $priceStartInput = $filterForm.find('#startPrice'),
      $priceStartInput = document.getElementById('startPrice'),
      // $priceEndInput = $filterForm.find('#endPrice');
      $priceEndInput = document.getElementById('endPrice');

    const $clearFiltersButton = $('#clear-filters-button');

    const priceFormat = wNumb({
      mark: '.',
      prefix: '£',
      decimals: 0,
      edit: function(number) {
        return number == '£300' ? number + '+' : number;
      }
    });

    const minPrice = filters.minPrice ? filters.minPrice : 0;
    const maxPrice = filters.maxPrice ? filters.maxPrice : 300;

    noUiSlider.create(filterSlider, {
      tooltips: [priceFormat, priceFormat],
      start: [minPrice, maxPrice],
      connect: true,
      step: 10,
      range: {
        min: 0,
        max: 300
      }
    });

    filterSlider.noUiSlider.on('change', function(values, handle) {
      var value = values[handle];

      if (handle) {
        // priceEndInput ||= document.getElementById('endPrice');
        $priceEndInput.value = value;
        // $priceEndInput.val(value);
      } else {
        // priceStartInput ||= document.getElementById('startPrice');
        // $priceStartInput.val(value);
        $priceStartInput.value = value;
      }

      const event = new CustomEvent(`priceRangeSelected`, {
        bubbles: true,
        cancelable: true
      });
      filterSlider.dispatchEvent(event);
    });

    $clearFiltersButton.on('click', function() {
      $filterForm[0].reset();
      $filterForm.find('select').prop('selectedIndex', 0);
      $filterForm.find('.price_value').val('');
      $filterForm.find('.date_input').val('');
      $filterForm.find('[name="locations[]"').prop('checked', false);
      $filterForm.find('[name="facilities[]"').prop('checked', false);
      filterSlider.noUiSlider.set([0, 300]);
      $calendarButtons.each(function(index) {
        $calendarButtons[index].children[0].innerText =
          $calendarButtons[index].dataset.text;
      });

      $('#searchFilters').collapse('hide');

      const event = new CustomEvent(`filtersCleared`, {
        bubbles: true,
        cancelable: true
      });
      $clearFiltersButton[0].dispatchEvent(event);
    });

    $(document).on('turbolinks:before-cache', () => {
      if (filterSlider.noUiSlider) {
        filterSlider.noUiSlider.destroy();
      }
    });
  }
};
