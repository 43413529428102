import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let currentURL = new URL(window.location.href);

    let categoryAndSupplierRegex = /^\/search\/category\/(.*?)\/supplier\/(.*)$/;
    let categoryRegex = /^\/search\/category\/(.*?)$/;

    if (currentURL.pathname.match(categoryAndSupplierRegex)) {
      let category = currentURL.pathname.match(categoryAndSupplierRegex)[1];
      let supplier = currentURL.pathname.match(categoryAndSupplierRegex)[2];

      currentURL.pathname = '/search';
      currentURL.searchParams.set('category', category);
      currentURL.searchParams.set('supplier', supplier);

      window.history.replaceState({}, '', currentURL.toString());
    } else if (currentURL.pathname.match(categoryRegex)) {
      let category = currentURL.pathname.match(categoryRegex)[1];

      currentURL.pathname = '/search';
      currentURL.searchParams.set('category', category);

      window.history.replaceState({}, '', currentURL.toString());
    }
  }
}
