import { Controller } from 'stimulus';
import { debounceSubmit } from '../helpers/formHelper';

const BREAKPOINT = 768;

export default class extends Controller {
  static targets = ['form'];

  initialize() {
    this.rewriteUrl = this.rewriteUrl.bind(this);
  }

  connect() {
    this.form = this.hasFormTarget ? this.formTarget : this.element;
    this.debounceSubmit = debounceSubmit(this.form);

    if (window.location.href.indexOf('search') != -1) {
      this.applyFilters();
    }
  }

  submitSelection(event) {
    const bigScreen = window.innerWidth >= BREAKPOINT;
    const isLocation = event.type === 'location:changed';
    const isPriceOrder = event.target.id === 'experiences_search_pricing_order';

    this.rewriteUrl();
    // The exceptions are there so you don't have to open the filter panel on mobile
    // to submit inputs that aren't on the filter panel.
    if (bigScreen || isLocation || isPriceOrder) {
      this.debounceSubmit();
    }
  }

  // We want to update browser history so the user can refresh the page and get the same filter settings.
  rewriteUrl() {
    const newUrl = new URL(window.location);
    newUrl.search = '';

    // Pull out a series of [param, value] arrays from the search form, and remove all the ones
    // that have no value set.
    const formData = Array.from(new FormData(this.form)).filter(
      arr => arr[1] != ''
    );

    // Stick the params we extracted from the form onto our URL
    const searchParams = new URLSearchParams(formData);
    searchParams.forEach((value, key) =>
      newUrl.searchParams.append(key, value)
    );

    window.history.pushState({}, '', newUrl);
  }

  applyFilters() {
    if (window.innerWidth < BREAKPOINT) {
      const searchResults = document.getElementById(
        'experience-search-results'
      );
      searchResults.scrollIntoView(true);
    }
  }
}
