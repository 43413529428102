import { BaseController } from 'stimulus-library';

const ADDRESS_INPUTS = [
  'city',
  'country_code',
  'line_1',
  'line_2',
  'postal_code'
];

export default class extends BaseController {
  static targets = ['newForm', 'saveCardCheck', 'name'];

  connect() {
    $(this.newFormTarget).on('hidden.bs.collapse', () => {
      this.nameTarget.disabled = true;
    });

    $(this.newFormTarget).on('show.bs.collapse', () => {
      this.nameTarget.disabled = false;
    });
  }

  select(event) {
    if (event.currentTarget.value) {
      $(this.newFormTarget).collapse('hide');

      const billingAddress = JSON.parse(
        event.currentTarget.dataset.billingAddress
      );

      let input;
      ADDRESS_INPUTS.forEach(item => {
        input = document.getElementById(item);

        input.value = billingAddress[item];
        input.dispatchEvent(new Event('input'));
      });

      this.saveCardCheckTarget.querySelector(
        'input[type="checkbox"]'
      ).checked = false;
      this.saveCardCheckTarget.style.display = 'none';
    } else {
      $(this.newFormTarget).collapse('show');

      ADDRESS_INPUTS.forEach(item => {
        if (item != 'country_code') {
          document.getElementById(item).value = '';
        }
      });

      this.saveCardCheckTarget.style.display = 'block';

      // Event listener set in paymentForm.js
      const event = new Event('cardFormLoaded');
      window.dispatchEvent(event);
    }
  }
}
