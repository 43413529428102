const date = function date(dateStr) {
  return new Date(dateStr);
};

const humanDate = function humanDate(dateStr) {
  return date(dateStr).toLocaleString('default', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const humanDateShort = function humanDateShort(dateStr) {
  return date(dateStr).toLocaleString('default', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

const datesHelper = {
  date: date,
  humanDate: humanDate,
  humanDateShort: humanDateShort
};

export default datesHelper;
