import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['primary', 'secondary'];

  primaryChanged(e) {
    if (!this.primaryTarget.checked) {
      this.secondaryTargets.forEach(element => {
        element.checked = false;
      });
    }
  }

  // when primary checked, disable secondary checkboxes
  disableSecondary(e) {
    this.secondaryTargets.forEach(element => {
      element.disabled = this.primaryTarget.checked;
    });
  }

  // when primary NOT checked, enable secondary checkboxes
  enableSecondary(e) {
    this.secondaryTargets.forEach(element => {
      element.disabled = !this.primaryTarget.checked;
    });
  }
}
