import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'modal', 'recurrenceInput'];

  connect() {
    console.log('Connected to availability-confirm-modal controller');
  }

  submit(event) {
    if (this.shouldConfirm) {
      event.preventDefault();
      $(this.modalTarget).modal('show');
    }
  }

  confirm() {
    this.formTarget.submit();
  }

  get shouldConfirm() {
    return (
      !this.recurrenceInputTarget.disabled &&
      this.recurrenceInputTarget.value != 'null'
    );
  }
}
