import { Controller } from 'stimulus';

// This is used to change the text on a jquery ui toggle control when it changes state.
//
// Usage:
//      <---------------------jquery------------------------------------> <-----------------------------this controller ---------------------------------------------------------->
// %div{ role: "button", data: { toggle: "collapse", target: "#myTarget", controller: "toggle-text", toggle_text: { collapsed_value: "Expand me!", expanded_value: "Collapse me!" } } }
// #myTarget I'm the content!
export default class extends Controller {
  static values = {
    collapsed: String,
    expanded: String
  };

  initialize() {
    this.observerOptions = {
      attributes: true,
      attributeFilter: ['class']
    };
    this.updateText = this.updateText.bind(this);

    this.observer = new MutationObserver(this.updateText);
  }

  connect() {
    // Simulate a toggle to get the initial state
    this.element.classList.toggle('collapsed');
    this.updateText();

    this.observer.observe(this.element, this.observerOptions);
  }

  disconnect() {
    this.observer.disconnect();
  }

  // The parameters are ignored because we already have a reference to the correct node.
  updateText(_mutations, _observer) {
    this.element.textContent = this.element.classList.contains('collapsed')
      ? this.collapsedValue
      : this.expandedValue;
  }
}
