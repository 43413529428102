import { Controller } from 'stimulus';
const ACTIVE_CLASS = 'active';

export default class extends Controller {
  static targets = ['tab'];

  activate() {
    this.tabTargets.forEach(tab => tab.classList.remove(ACTIVE_CLASS))
    event.currentTarget.classList.add(ACTIVE_CLASS);
  }
}
