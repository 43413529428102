import { Controller } from 'stimulus';
import Glide, { Swipe, Breakpoints, Controls, Autoplay } from '@glidejs/glide/dist/glide.modular.esm';

export default class extends Controller {
  connect() {
    this.carousel = new Glide(this.element, {
      type: 'carousel',
      autoplay: 4000,
      animationDuration: 1000,
      gap: 15,
      perView: 2,
      breakpoints: {
        576: {
          perView: 1
        }
      },
    });
    this.carousel.mount({
      Swipe,
      Breakpoints,
      Controls,
      Autoplay
    });
  }

  disconnect() {
    this.carousel.destroy();
  }
}
