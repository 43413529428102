import { Controller } from 'stimulus';
import Glide, {
  Autoplay,
  Breakpoints,
  Controls,
  Keyboard,
  Swipe
} from '@glidejs/glide/dist/glide.modular.esm';

export default class extends Controller {
  connect() {
    this.carousel = new Glide(this.element, {
      type: 'carousel',
      perView: 1,
      peek: 160,
      gap: 24,
      animationDuration: 100,
      autoplay: false,
      breakpoints: {
        900: {
          peek: 100,
          gap: 16
        },
        576: {
          animationDuration: 100,
          peek: { before: 0, after: 50 },
          gap: 10
        }
      }
    });
    this.carousel.mount({
      Controls,
      Swipe,
      Breakpoints,
      Autoplay,
      Keyboard
    });
  }

  disconnect() {
    this.carousel.destroy();
  }
}
