import debounce from 'lodash/debounce';
import Rails from 'rails-ujs';

function toggleDescendants(element, disabled) {
  element.querySelectorAll('input, textarea, select').forEach(decendant => {
    decendant.disabled = disabled;
  });
}

// Returns a function that debounces submissions on a rails ujs remote form
export function debounceSubmit(form) {
  return debounce(() => Rails.fire(form, 'submit'), 900);
}

const enableDescendants = function enableDescendants(element) {
  toggleDescendants(element, false);
};

const disableDescendants = function disableDescendants(element) {
  toggleDescendants(element, true);
};

const formHelper = {
  enableDescendants: enableDescendants,
  disableDescendants: disableDescendants
};

export default formHelper;
