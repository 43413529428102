import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['label'];

  updateLabel() {
    const labelText = event.target.files.length
      ? event.target.files[0].name
      : 'Choose file...';
    this.labelTarget.textContent = labelText;
  }
}
