import { last, update } from 'lodash';
import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    locked: Array,
    last: String
  };
  // formTarget: The fields used by the MultiSort viewmodel
  // headerTargets: Each column header
  static targets = ['form', 'header', 'lock', 'unlock'];

  initialize() {
    this.lock = this.lock.bind(this);
    this.unlock = this.unlock.bind(this);
    this.sort = this.sort.bind(this);
    this.sortKey = this.sortKey.bind(this);
  }

  connect() {
    this.headerTargets.forEach(element => {
      element.addEventListener('click', this.sort);
    });

    this.lockTargets.forEach(element => {
      element.addEventListener('click', this.lock);
    });

    this.unlockTargets.forEach(element => {
      element.addEventListener('click', this.unlock);
    });
  }

  disconnect() {
    this.headerTargets.forEach(element => {
      element.removeEventListener('click', this.sort);
    });

    this.lockTargets.forEach(element => {
      element.removeEventListener('click', this.lock);
    });

    this.unlockTargets.forEach(element => {
      element.removeEventListener('click', this.unlock);
    });
  }

  updateSort() {
    this.formTarget.querySelector(
      "input[id$='locked']"
    ).value = this.lockedValue;
    this.formTarget.requestSubmit();
  }

  sortKey(event) {
    return event.target.closest('th').dataset.sortKey;
  }

  setOrFlip(str, event) {
    const sortKey = this.sortKey(event);
    const isLocked = this.formTarget
      .querySelector("input[id$='locked']")
      .value.includes(sortKey);

    if (isLocked) {
      return str;
    }

    const matches = str.startsWith(this.sortKey(event));
    const ascending = str.endsWith('asc');
    return `${sortKey} ${matches && ascending ? 'desc' : 'asc'}`;
  }

  sort(event) {
    const lastInput = this.formTarget.querySelector("input[id$='last']");
    lastInput.value = this.setOrFlip(lastInput.value, event);

    this.formTarget.requestSubmit();
  }

  lock(event) {
    const alreadyLocked = this.lockedValue.some(e => {
      return e.includes(this.sortKey(event));
    });
    const currentlySorting = this.lastValue.includes(this.sortKey(event));

    if (alreadyLocked || !currentlySorting) {
      return;
    }

    this.lockedValue = [
      ...this.lockedValue,
      this.formTarget.querySelector("input[id$='last']").value
    ];
    this.updateSort();
  }

  unlock(event) {
    this.lockedValue = this.lockedValue.filter(e => {
      return !e.includes(this.sortKey(event));
    });
    this.updateSort();
  }
}
