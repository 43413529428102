/*eslint-disable */
export default function turbolinksScrollPersistence() {
  Turbolinks.scroll = {};

  document.addEventListener('turbolinks:load', () => {
    const elements = document.querySelectorAll('[data-turbolinks-scroll]');

    elements.forEach(function(element) {
      element.addEventListener('click', () => {
        Turbolinks.scroll['top'] = document.scrollingElement.scrollTop;
      });

      element.addEventListener('submit', () => {
        Turbolinks.scroll['top'] = document.scrollingElement.scrollTop;
      });
    });
  });

  document.addEventListener('turbolinks:render', () => {
    if (Turbolinks.scroll['top']) {
      document.scrollingElement.scrollTo(0, Turbolinks.scroll['top']);
    }

    Turbolinks.scroll = {};
  });
}
/*eslint-enable */
