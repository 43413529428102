import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    console.log('Rejection controller is connected');

    let rejection_note = document.getElementById(
      'booking_state_transitioned_note'
    );
    rejection_note.addEventListener('blur', function() {
      console.log('bluurrr');
      let error_label = document.getElementsByClassName(
        'invalid-feedback invalid-feedback has-error-regex'
      );
      error_label[error_label.length - 1].innerText =
        'Giving a reason for rejecting a booking is required before a booking can be rejected';
    });
  }
}
