import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['autoShowModal', 'disposeOnClose'];

  connect() {
    if (this.hasDisposeOnCloseTarget) {
      let modal = $(this.disposeOnCloseTarget);
      modal.one('hidden.bs.modal', () => modal.remove());
    }

    if (this.hasAutoShowModalTarget) {
      setTimeout(() => $(this.autoShowModalTarget).modal('show'), 1000);
    }
  }
}
