import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'inbox',
    'threads',
    'content',
    'button',
    'messageThread',
    'initialActiveThread'
  ];

  initialize() {
    this.messageThreadTarget.scrollTop = this.messageThreadTarget.scrollHeight;
  }

  messagesLoaded() {
    this.messageThreadTarget.scrollTop = this.messageThreadTarget.scrollHeight;
  }

  selectMessage() {
    this.buttonTarget.disabled = false;
    this.threadsTarget.classList.add('inbox__threads--hidden');
    this.contentTarget.classList.remove('inbox__content--hidden');
    this.initialActiveThreadTarget.classList.remove('active--initial');
  }

  openThreads() {
    this.buttonTarget.disabled = true;
    this.inboxTarget.classList.remove('message_active');
    this.contentTarget.classList.add('inbox__content--hidden');
    this.threadsTarget.classList.remove('inbox__threads--hidden');
  }
}
