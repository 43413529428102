import { BaseController } from 'stimulus-library';

const orphanedSeatsSelectedMessage = `
Leaving individual isolated seats is not allowed at this venue.
<br>
Please review your seat selection above for more details
`

const orphanedExemptSeatsSelectedMessage = `
Please try not to leave any individual isolated seats.
<br>
We understand this may not always be possible when booking accessible seating
`

const onlyCompanionSeatSelectedMessage = `
Companion seats must be selected along with another seat.
<br>
Please review your seat selection above for more details
`

export default class extends BaseController {
  static values = {
    workspaceKey: String,
    eventKey: String,
    pricing: Array,
    selectedSeats: Array,
    channelId: String,
    channelName: String,
  }

  static targets = ['quantity', 'selectedSeats', 'holdToken', 'selectionInvalid', 'orphanError', 'submitButton']

  connect() {
    this.noOrphanedSeats = true
    this.exemptSeatsSelected = false

    if (window.seatsio) {
      this.renderChart()
    } else {
      const script = document.createElement('script')
      script.src = 'https://cdn-eu.seatsio.net/chart.js'
      script.onload = this.renderChart.bind(this)
      document.head.appendChild(script)
    }

    document.addEventListener('ticketStepRendered', this.onTicketStepRendered.bind(this))

    this.form = this.element.closest('form')

    if (this.form) {
      this.form.addEventListener('submit', this.onSubmit.bind(this))
    }
  }

  onSubmit(e) {
    if (!this.isValid) {
      e.preventDefault()
      e.stopImmediatePropagation()
    }
  }

  onTicketStepRendered() {
    if (!window.seatsio) return

    this.chart?.destroy()
    this.chart = null
    this.renderChart()
  }

  renderChart() {
    if (this.chart) return;

    this.chart = new seatsio.SeatingChart({
      divId: 'chart',
      workspaceKey: this.workspaceKeyValue,
      event: this.eventKeyValue,
      session: this.userOwnsSeats ? 'continue' : 'start',
      pricing: this.pricingValue,
      priceFormatter: function(price) {
        return `£${price}`
      },
      selectedObjects: this.selectedSeatsValue,
      objectWithoutPricingSelectable: false,
      onHoldSucceeded: this.updateTicketInputQuantities.bind(this),
      onReleaseHoldSucceeded: this.updateTicketInputQuantities.bind(this),
      onChartInitialized: this.updateTicketInputQuantities.bind(this),
      onChartRendered: this.updateTicketInputQuantities.bind(this),
      onSessionInitialized: this.onSessionInitialized.bind(this),
      channels: this.channelIdValue ? this.channelIdValue.split(',') : null,
      tooltipInfo: this.channelIdValue ? (object => object.isInChannel(this.channelIdValue.split(',')[0]) ? this.channelNameValue : null) : null,
      onSelectionInvalid: this.onSelectionInvalid.bind(this),
      onSelectionValid: this.onSelectionValid.bind(this),
      selectionValidators: [
        { type: 'noOrphanSeats' }
      ]
    }).render()
  }

  onSessionInitialized(e) {
    this.holdTokenTarget.value = e.token

    const event = new CustomEvent('seatsSessionInitialized', {
      detail: {
        expiresInSeconds: e.expiresInSeconds
      }
    })
    document.dispatchEvent(event)
  }

  async updateTicketInputQuantities() {
    const selectedSeats = await this.chart.listSelectedObjects()

    this.exemptSeatsSelected = this.isExemptSeatSelected(selectedSeats)
    this.onlyCompanionSeatSelected = this.isOnlyCompanionSeatSelected(selectedSeats)
    this.updateValidationBanner()

    this.userOwnsSeats = selectedSeats.length !== 0

    if (selectedSeats.length !== 0) {
      this.selectionInvalidTarget.value = false
    }

    this.pricingValue.forEach((category) => {
      category.ticketTypes.forEach((ticketType) => {
        const pricePointsSeats = selectedSeats.filter((seat) =>
          seat.selectedTicketType === ticketType.ticketType
        )

        const quantityInput = this.getQuantityInput(ticketType.pricePointId)
        quantityInput.value = pricePointsSeats.length

        const selectedSeatsInput = this.getSelectedSeatsInput(ticketType.pricePointId)
        selectedSeatsInput.value = JSON.stringify(pricePointsSeats.map((seat) => seat.label))
      })
    })
  }

  isExemptSeatSelected(selectedSeats) {
    for(const seat of selectedSeats) {
      const lowerCaseLabel = seat.category.label.toLowerCase()
      if (lowerCaseLabel.startsWith('wheelchair') || lowerCaseLabel.startsWith('companion')) {
        return true
      }
    }

    return false
  }

  isOnlyCompanionSeatSelected(selectedSeats) {
    let quantityCompanion = 0
    let quantityOther = 0

    for(const seat of selectedSeats) {
      const lowerCaseLabel = seat.category.label.toLowerCase()
      const lowerCaseSeatLabel = this.seatLabelFromType(seat.selectedTicketType).toLowerCase()
      if (lowerCaseLabel.startsWith('companion') || lowerCaseSeatLabel.startsWith('companion')) {
        quantityCompanion += 1
      } else {
        quantityOther += 1
      }
    }

    return quantityCompanion > 0 && quantityOther === 0
  }

  seatLabelFromType(seatType) {
    return this.pricingValue
      .flatMap((category) => category.ticketTypes)
      .find((pricePoint) => pricePoint.ticketType == seatType)
      .label
  }

  onSelectionValid() {
    this.noOrphanedSeats = true
    this.updateValidationBanner()
  }

  onSelectionInvalid(_reasons) {
    this.noOrphanedSeats = false
    this.updateValidationBanner()
  }

  updateValidationBanner() {
    this.orphanErrorTarget.classList.remove('alert-danger')
    this.orphanErrorTarget.classList.remove('alert-warning')

    if (this.onlyCompanionSeatSelected) {
      this.orphanErrorTarget.style.display = 'block'
      this.orphanErrorTarget.classList.add('alert-danger')
      this.orphanErrorTarget.innerHTML = onlyCompanionSeatSelectedMessage
      this.submitButtonTarget.disabled = true

      return
    }

    if (this.exemptSeatsSelected) {
      this.orphanErrorTarget.style.display = 'block'
      this.orphanErrorTarget.classList.add('alert-warning')
      this.orphanErrorTarget.innerHTML = orphanedExemptSeatsSelectedMessage
      this.submitButtonTarget.disabled = false

      return
    }

    if (this.isValid) {
      this.orphanErrorTarget.style.display = 'none'
      this.submitButtonTarget.disabled = false

      return
    }

    this.orphanErrorTarget.style.display = 'block'
    this.orphanErrorTarget.classList.add('alert-danger')
    this.orphanErrorTarget.innerHTML = orphanedSeatsSelectedMessage
    this.submitButtonTarget.disabled = true
  }

  getQuantityInput(pricePointId) {
    return this.quantityTargets.find((quantity) => quantity.id === `seats-${pricePointId}-quantity`)
  }

  getSelectedSeatsInput(pricePointId) {
    return this.selectedSeatsTargets.find((quantity) => quantity.id === `seats-${pricePointId}-selected-seats`)
  }

  get userOwnsSeats() {
    return localStorage.getItem('seatsUserOwnsSeats') === 'true'
  }

  set userOwnsSeats(value) {
    return localStorage.setItem('seatsUserOwnsSeats', value)
  }

  get isValid() {
    console.log(this.noOrphanedSeats || this.exemptSeatsSelected)
    console.log(!this.onlyCompanionSeatSelected)
    return (this.noOrphanedSeats || this.exemptSeatsSelected) && !this.onlyCompanionSeatSelected
  }
}
