import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

const MODAL_SELECTOR = '#wishlist-modal';
const MODAL_CONTENT_SELECTOR = '.js-wishlist-modal--content';
const MODAL_ADD_WISHLIST_SELECTOR = '.js-wishlist-add-content';

export default class extends Controller {
  static targets = ['icon'];

  openModal() {
    Rails.ajax({
      url: this.data.get('url'),
      type: 'GET',
      dataType: 'json',
      success: data => {
        injectModalContent(data.modal_content_html);
      }
    });
  }

  newWishlist(event) {
    Rails.ajax({
      url: event.currentTarget.dataset.url,
      type: 'GET',
      dataType: 'json',
      success: data => {
        toggleAddWishlistContent(data.modal_content_html);
      }
    });
  }

  addToWishlist(event) {
    Rails.ajax({
      url: event.currentTarget.dataset.url,
      type: 'POST',
      dataType: 'json',
      success: data => {
        setIcon(data.experience_id, true);
        injectModalContent(data.modal_content_html);

        let wishlistButton = document.querySelector(
          `[data-wishlist-id="${data.wishlist_id}"]`
        );

        if (wishlistButton) {
          addWishlistToPage(data.wishlist_id, data.experience_id);
        }
      }
    });
  }

  removeFromWishlist(event) {
    Rails.ajax({
      url: event.currentTarget.dataset.url,
      type: 'POST',
      dataType: 'json',
      success: data => {
        setIcon(data.experience_id, data.active);
        injectModalContent(data.modal_content_html);

        let wishlistButton = document.querySelector(
          `[data-wishlist-id="${data.wishlist_id}"]`
        );

        if (wishlistButton) {
          removeWishlistFromPage(data.wishlist_id, data.experience_id);
        }
      }
    });
  }

  editWishlist(event) {
    Rails.ajax({
      url: event.currentTarget.dataset.url,
      type: 'GET',
      dataType: 'json',
      success: data => {
        document.querySelector('.js-edit-modal--content').innerHTML =
          data.modal_content_html;

        $('#modal-edit').modal('show');
      }
    });
  }

  createWishlistSuccess(event) {
    const experienceId = event.detail[0].experience_id;

    setIcon(experienceId, true);
    injectModalContent(event.detail[0].modal_content_html);
  }

  createWishlistError(event) {
    const html = event.detail[0].modal_content_html;
    toggleAddWishlistContent(html);
  }

  updateWishlistSuccess(event) {
    const name = event.detail[0].wishlist.name;
    const id = event.detail[0].wishlist.id;

    document.querySelector(
      `[data-wishlist-id='${id}'] .js-wishlist-name`
    ).innerHTML = name;

    document.querySelector('.js-wishlist-heading').innerHTML = name;

    $('#modal-edit').modal('hide');
  }

  updateWishlistError(event) {
    const html = event.detail[0].modal_content_html;

    document.querySelector('.js-edit-modal--content').innerHTML = html;
  }
}

function setIcon(experienceId, active) {
  var $icon = $(`[data-wishlist-experience-id='${experienceId}']`);

  if (active) {
    $icon.addClass($icon.data('active-class'));

    $icon.tooltip('hide');
    $icon.tooltip('disable');
  } else {
    $icon.removeClass($icon.data('active-class'));

    $icon.tooltip('enable');
  }
}

function injectModalContent(html) {
  document.querySelector(MODAL_CONTENT_SELECTOR).innerHTML = html;
  $(MODAL_SELECTOR).modal('show');
}

function toggleAddWishlistContent(html) {
  document.querySelector(MODAL_ADD_WISHLIST_SELECTOR).innerHTML = html;
}

function addWishlistToPage(wishlist_id, experience_id) {
  incrementWishlistButton(wishlist_id);
  showExperienceOnPage(wishlist_id, experience_id);
}

function removeWishlistFromPage(wishlist_id, experience_id) {
  decrementWishlistButton(wishlist_id);
  hideExperienceOnPage(wishlist_id, experience_id);
}

function incrementWishlistButton(wishlist_id) {
  let wishlistButton = document.querySelector(
      `[data-wishlist-id="${wishlist_id}"]`
    ),
    wishlistButtonNumberNode = wishlistButton.querySelector('.lead'),
    wishlistButtonValue = parseInt(wishlistButtonNumberNode.innerText),
    newWishlistButtonValue = (wishlistButtonValue += 1);

  wishlistButtonNumberNode.innerText = newWishlistButtonValue;

  // If the wishlist was showing an empty message, remove it
  const wishlistMessage = document.querySelector('.js-wishlist-message');
  if (wishlistMessage) {
    wishlistMessage.remove();
  }
}

function decrementWishlistButton(wishlist_id) {
  let wishlistButton = document.querySelector(
      `[data-wishlist-id="${wishlist_id}"]`
    ),
    wishlistButtonNumberNode = wishlistButton.querySelector('.lead'),
    wishlistButtonValue = parseInt(wishlistButtonNumberNode.innerText),
    newWishlistButtonValue = (wishlistButtonValue -= 1),
    wishlistIsSelected = wishlistIdIsSelected(wishlist_id);

  wishlistButtonNumberNode.innerText = newWishlistButtonValue;

  // If the new wishlist total is 0, show message
  if (wishlistIsSelected && newWishlistButtonValue == 0) {
    const tabMessageWrapper = document.createElement('div'),
      tabMessageDiv = document.createElement('div'),
      tabMessage = document.createElement('p');

    tabMessageWrapper.classList.add(
      'row',
      'justify-content-center',
      'js-wishlist-message'
    );
    tabMessageDiv.classList.add('col-12', 'col-md-8', 'text-center');
    tabMessage.classList.add('font-weight-bold');

    tabMessage.innerText = 'This wishlist is empty';

    tabMessageDiv.appendChild(tabMessage);
    tabMessageWrapper.appendChild(tabMessageDiv);

    document
      .querySelector('.js-updateable-tab-content')
      .appendChild(tabMessageWrapper);
  }
}

function wishlistIdIsSelected(wishlist_id) {
  // Check which wishlist is currently active
  const selectedWishlist = document.querySelector(`[data-selected-wishlist]`),
    selectedWishlistId = parseInt(selectedWishlist.dataset.selectedWishlist);

  return wishlist_id == selectedWishlistId;
}

function showExperienceOnPage(wishlist_id, experience_id) {
  let experienceListing = document.querySelector(
      `[data-experience-id="${experience_id}"]`
    ),
    wishlistIsSelected = wishlistIdIsSelected(wishlist_id);

  if (wishlistIsSelected && experienceListing) {
    experienceListing.style.display = 'block';
  }
}

function hideExperienceOnPage(wishlist_id, experience_id) {
  let experienceListing = document.querySelector(
      `[data-experience-id="${experience_id}"]`
    ),
    wishlistIsSelected = wishlistIdIsSelected(wishlist_id);

  if (wishlistIsSelected && experienceListing) {
    experienceListing.style.display = 'none';
  }
}
