import 'bootstrap/js/dist/collapse';

document.addEventListener('click', ({ target }) => {
  if (target.matches('.rs_save') || target.matches('.rs_cancel')) {
    hideOrShowEndDateField();
  }
});

document.addEventListener('change', ({ target }) => {
  if (target.matches('.recurring_select')) {
    hideOrShowEndDateField();
  } else if (target.matches('.js-recurrence-never-ends')) {
    const endDateInput = document.querySelector('.js-end-date-selector input');
    const endDateButton = document.querySelector(
      '.js-end-date-selector button'
    );

    if (target.checked == true) {
      endDateInput.setAttribute('disabled', true);
      endDateButton.setAttribute('disabled', true);
    } else {
      endDateInput.removeAttribute('disabled');
      endDateButton.removeAttribute('disabled');
    }
  }
});

function hideOrShowEndDateField() {
  const input = document.querySelector('.recurring_select');

  if (input && input.value == 'null') {
    $('#end-date-fieldset').collapse('hide');
  } else if (input.value == 'custom') {
    $('#end-date-fieldset').collapse('show');
    addModalBackdrop();
  } else {
    $('#end-date-fieldset').collapse('show');
  }
}

function addModalBackdrop() {
  const backdrop = document.createElement('div');
  backdrop.classList.add('modal-backdrop', 'show');
  document.body.appendChild(backdrop);

  // Remove backdrop handler
  document.addEventListener('click', ({ target }) => {
    if (
      target.matches('.rs_dialog_holder') ||
      target.matches('.rs_save') ||
      target.matches('.rs_cancel')
    ) {
      backdrop.remove();
    } else if (target.getAttribute('title') === 'Cancel') {
      event.preventDefault();
      backdrop.remove();
    }
  });
}
