import { Controller } from 'stimulus';
import bootstrapValidate from 'bootstrap-validate';

/*
 * validates required inputs for presence
 */
export default class extends Controller {
  connect() {
    this.element.addEventListener('submit', this.onSubmit);

    this.formFields.forEach(field => {
      if (this.shouldValidateField(field)) {
        const fieldName = field.dataset.validationName || 'This';
        const rule = `regex:^.*[a-zA-Z0-9]+.*$: ${fieldName} is a required field`;

        bootstrapValidate(
          field,
          rule,
          (isValid => {
            if (!isValid) {
              this.isValid = false;
            }
          }).bind(this)
        );

        field.addEventListener('blur', () => {
          field.dispatchEvent(new Event('input'));
        });
      }
    });
  }

  disconnect() {
    this.element.removeEventListener('submit', this.onSubmit);
  }

  onSubmit = event => {
    if (!this.formIsValid) {
      event.preventDefault();
      event.stopImmediatePropagation();

      this.firstInvalidField.focus();
    }
  };

  shouldValidateField(field) {
    // bootstrap-validate doesn't seem to support textarea
    // validations, so adding it manually here
    if (field.type === 'textarea' && !field.disabled && field.required) {
      this.setupTextAreaValidations(field);
      return false;
    } else {
      return !field.disabled && field.required;
    }
  }

  setupTextAreaValidations(field) {
    field.addEventListener('blur', () => {
      this.validateTextArea(field);
    });

    this.element.addEventListener('submit', () => {
      this.validateTextArea(field);
    });
  }

  validateTextArea(field) {
    let isValid = field.checkValidity();
    let validationMessage =
      field.dataset.customValidationMessage || 'This is a required field';

    if (/^\s*$/g.test(field.value)) {
      isValid = false;
    }

    this.removeErrorMessage(field);

    if (!isValid && field.required) {
      field.classList.add('is-invalid');
      field.parentElement.insertAdjacentHTML(
        'beforeend',
        `<div class="invalid-feedback d-inline-block">${validationMessage}</div>`
      );
      return false;
    }

    return true;
  }

  removeErrorMessage(field) {
    const fieldContainer = field.closest('.form-group');
    if (!fieldContainer) return;

    const existingErrorMessageElement = fieldContainer.querySelector(
      '.invalid-feedback'
    );
    if (existingErrorMessageElement) {
      field.classList.remove('is-invalid');
      existingErrorMessageElement.parentNode.removeChild(
        existingErrorMessageElement
      );
    }
  }

  get formFields() {
    return Array.from(this.element.elements);
  }

  get formIsValid() {
    this.isValid = true;

    this.formFields.forEach(field => {
      if (field.type === 'textarea' && !this.validateTextArea(field)) {
        if (field.required) {
          this.isValid = false;
        } else {
          this.removeErrorMessage(field);
        }
      } else {
        field.dispatchEvent(new Event('input'));
      }
    });

    return this.isValid;
  }

  get firstInvalidField() {
    return this.formFields.find(field =>
      field.classList.contains('is-invalid')
    );
  }
}
