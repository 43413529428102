import $ from 'jquery';
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import Appsignal from '@appsignal/javascript';
import { installErrorHandler } from '@appsignal/stimulus';
import 'form-request-submit-polyfill';
import 'lodash';
//TODO: Split into only what we need from bootstrap
import 'bootstrap/dist/js/bootstrap';
import 'cocoon';
import 'jquery-postcodes';
import 'recurring-select';
import * as noUiSlider from 'nouislider';
import * as wNumb from 'wnumb';
import 'focus-visible';
import LazyLoad from 'vanilla-lazyload';
import 'objectFitPolyfill';
import { default as PolyfilledResizeObserver } from 'resize-observer-polyfill';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import './lib/modals';
import './lib/customFileSelect';
import './lib/recurringSelect';
import {
  CheckboxEnableInputsController,
  EqualizeController,
  SignalInputController,
  SignalVisibilityController,
  ToggleClassController,
} from 'stimulus-library';
import 'tom-select/dist/js/tom-select.complete';

if (!('ResizeObserver' in window)) {
  window.ResizeObserver = PolyfilledResizeObserver;
}

window.LazyLoad = new LazyLoad();
$(document).on('turbolinks:load', () => {
  window.LazyLoad.update();
  window.objectFitPolyfill();
});

window.noUiSlider = noUiSlider;
window.wNumb = wNumb;

$(document).on('cocoon:after-insert', '#page_parts', function(e, insertedItem) {
  var id =
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15);
  id = 'id' + id;
  var element = $(insertedItem).find('.js-cocoon-content-html');
  element.attr('id', id);
  window.addTinyMce(element, id);
});

// Enable Bootstrap's tooltip
$('[data-toggle="tooltip"]').tooltip();
$(document).on('turbolinks:render turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip();
});

$(document).on('turbolinks:before-cache', () => {
  $('[data-toggle="tooltip"]').tooltip('dispose');
});

Rails.start();
Turbolinks.start();

// eslint-disable-next-line no-undef
const appsignal = new Appsignal({ key: process.env.APPSIGNAL_JS_PUSH_API_KEY });

window.appsignal = appsignal;

const application = Application.start();
const context = require.context('../javascript/controllers', true, /\.js$/);
application.load(definitionsFromContext(context));
installErrorHandler(appsignal, application);

application.register('checkbox-enable-inputs', CheckboxEnableInputsController);
application.register('equalize', EqualizeController);
application.register('signal-input', SignalInputController);
application.register('signal-visibility', SignalVisibilityController);
application.register('toggle-class', ToggleClassController);

// eslint-disable-next-line no-undef
application.debug = process.env.NODE_ENV === 'development';
