import Rails from 'rails-ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['points'];

  feedPoints(event) {
    let selection = event.currentTarget.value;

    Rails.ajax({
      type: 'GET',
      url: event.currentTarget.dataset.url,
      dataType: 'json',
      success: data => {
        let points = data.find(field => field.reason == selection).points;

        this.pointsTarget.value = points;
      },
      error: () => {
        this.pointsTarget.value = 0;
      }
    });
  }
}
