// OPTIMIZE: consider replacing uses of this with the BookingCalendar stimulus controller, or similar
// This is currently used for the embedded widget, supplier time suggestions, and customer time suggestions.

import Rails from 'rails-ujs';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import forEach from 'lodash/forEach';

const BOOKING_FORM_SELECTOR = '.js-booking-form';
const CLASS_ACTIVE_DAY = 'y-bg-event--active';

export default function() {
  document.addEventListener('turbolinks:load', () => {
    initCalendar();
  });

  initCalendar();
}

function initCalendar() {
  const calendarEl = document.getElementById('booking-calendar');
  const timeSlotsContainer = document.getElementById('time-slots-results');

  if (calendarEl) {
    var today = new Date();
    var calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, interactionPlugin],
      eventDisplay: 'background',
      startParam: 'start_time',
      contentHeight: 'auto',
      endParam: 'end_time',
      firstDay: 1,
      fixedWeekCount: false,
      initialDate: getInitialDate(),
      validRange: {
        start: new Date(today.setDate(1)),
        end: new Date(today.setMonth(today.getMonth() + 12, 0))
      },
      events: {
        url: calendarEl.dataset.eventsPath,
        extraParams: () => {
          return { required_capacity: requiredCapacity() };
        }
      },
      eventClassNames: ({ event }) => {
        const element = document.querySelector(`td[data-date='${event.startStr}']`)

        element.classList.toggle('y-available', event.extendedProps.status !== 'unavailable')
      },
      eventClick: info => {
        setActiveClassFor(info);
        storeSelectedDate(info.event.startStr);
        fetchTimeSlots(calendarEl, timeSlotsContainer, info.event.startStr);
        showTooltipToast();
        showCancellationMessage(
          info.event.startStr,
          calendarEl.dataset.cancellationPolicyDate
        );
      },
      eventDidMount: info => {
        checkForActiveDay(calendarEl, info);
      }
    });

    calendar.render();
    setupRefetchEvents(calendar, calendarEl, timeSlotsContainer);
    setButtonsTypeAttribute();
    setupTooltipToast();
  }

  setupFormChangeListener();
}

function storeSelectedDate(date) {
  const bookingForm = document.querySelector(BOOKING_FORM_SELECTOR);
  bookingForm.dataset.selectedDate = date;
}

function getSelectedDate() {
  const bookingForm = document.querySelector('#booking-calendar');
  return bookingForm.dataset.selectedDate;
}

function showCancellationMessage(
  selectedDateString,
  cancellationPolicyDateString
) {
  if (cancellationPolicyDateString === 'unlimited') return;

  const selectedDate = new Date(selectedDateString),
    cancellationDate = new Date(cancellationPolicyDateString);
  if (selectedDate < cancellationDate) {
    $('#js-cancellation-message').collapse('show');
  } else {
    $('#js-cancellation-message').collapse('hide');
  }
}

function setupTooltipToast() {
  const tooltipToastElement = document.querySelector('.y-tooltip-toast');
  if (tooltipToastElement) {
    tooltipToastElement.addEventListener('animationend', () => {
      tooltipToastElement.classList.remove('show');
    });
  }
}

function showTooltipToast() {
  document.querySelector('.y-tooltip-toast').classList.add('show');
  // const calendarPosition =
  //   document.getElementById('booking-calendar').getBoundingClientRect().top +
  //   window.pageYOffset;

  // if (calendarPosition > window.scrollY) {
  //   window.scrollTo({ top: calendarPosition, behavior: 'smooth' });
  // }
}

function getInitialDate() {
  const selectedDate = getSelectedDate();

  // Check if a date has previously been selected
  if (selectedDate && selectedDate != '') {
    return new Date(selectedDate);
  }

  // Otherwise show calendar for tomorrows date
  // If today is the last day of the month, start calendar on the next day
  const tomorrow = new Date(1000 * 60 * 60 * 24 + +new Date());
  return tomorrow.getDate() === 1 ? tomorrow : false;
}

// Sum of all ticket quantity inputs
function requiredCapacity() {
  //TODO ticketing_participants_calc
  const calendarEl = document.getElementById('booking-calendar');

  if (calendarEl && calendarEl.dataset.requiredCapacity) {
    return calendarEl.dataset.requiredCapacity;
  }

  const inputs = document.querySelectorAll('.js-price-point-input');
  let sum = 0;
  let numberOfTickets = 0;
  let participantsPerTicket = 0;

  forEach(inputs, input => {
    numberOfTickets = parseInt(input.value) || 0;
    participantsPerTicket = parseInt(input.dataset.participantsPerTicket) || 1;

    sum += numberOfTickets * participantsPerTicket;
  });

  return sum;
}

// Selected time slot datetime
function selectedTime() {
  // if there's no selected, check for selected time attribute and preselect
  const input = document.querySelector('.js-time-slot-radio:checked');

  return input && input.value;
}

// Fetch time slots for a given date
function fetchTimeSlots(calendarEl, timeSlotsContainer, date) {
  Rails.ajax({
    url: `${
      calendarEl.dataset.timeSlotsPath
    }?date=${date}&required_capacity=${requiredCapacity()}&selected_time=${selectedTime()}&form_object=${
      calendarEl.dataset.formObject
    }`,
    type: 'get',
    dataType: 'json',
    success: function(data) {
      timeSlotsContainer.innerHTML = data;
      setSubmitButtonDisabledState();
      calendarEl.setAttribute('data-selected-date', date);

      if (calendarEl.dataset.suggestedBookingDate) {
        setSuggestedDateParams(timeSlotsContainer);
      }
    }
  });
}

// As the calendar is nested within a form, this prevents the buttons submitting the form.
function setButtonsTypeAttribute() {
  const fcButtons = document.querySelectorAll('.fc-button');

  forEach(fcButtons, button => {
    button.setAttribute('type', 'button');
  });
}

// When a user changes the number of tickets we need to refetch the calendar events
// and time slots.
function setupRefetchEvents(calendar, calendarEl, timeSlotsContainer) {
  document
    .getElementById('js-ticket-confirm')
    ?.addEventListener('click', () => {
      calendar.refetchEvents();
      const date = calendarEl.getAttribute('data-selected-date');
      date && fetchTimeSlots(calendarEl, timeSlotsContainer, date);
    });
}

// Set the enable the submit button if at least one ticket and a time slot is selected,
// otherwise disable it.
function setSubmitButtonDisabledState() {
  const submitButton = document.querySelector(
    BOOKING_FORM_SELECTOR + '--submit'
  );

  const selectedTickets = requiredCapacity() > 0;

  // Only complain if you haven't selected a time and you should've.
  const scheduleLaterField = document.getElementById('schedule_later');
  const calendarField = document.getElementById('booking-calendar');
  const selectedTimeSlot =
    selectedTime() || scheduleLaterField?.checked || !calendarField;

  const termsField = document.getElementById('terms_and_conditions');
  const termsAccepted = !termsField || termsField.checked; // When resheduling a booking no terms field is present

  if (selectedTickets && selectedTimeSlot && termsAccepted) {
    submitButton.disabled = false;
  } else {
    submitButton.disabled = true;
  }
}

// Check if submit button should be enabled whenever the form changes
function setupFormChangeListener() {
  const bookingForm = document.querySelector(BOOKING_FORM_SELECTOR);

  if (bookingForm) {
    bookingForm.addEventListener('change', () => {
      setSubmitButtonDisabledState();
    });
    bookingForm.addEventListener('input', () => {
      if (!event.target.classList.contains('js-submit-enable-ignore')) {
        setSubmitButtonDisabledState();
      }
    });
  }
}

// Find parent with the date as it's data attribute, if it's the target date,
// set it as active
function checkForActiveDay(calendar, day) {
  // eslint-ignore-next-line
  const initialDate = calendar.dataset.initialDate;
  const selectedDate = calendar.dataset.selectedDate;
  const dayDate = $(day.el)
    .closest('.fc-daygrid-day')
    .attr('data-date');

  if (dayDate === selectedDate) {
    setActiveClassFor(day);
  }

  if (dayDate === initialDate && initialDate === selectedDate) {
    setActiveClassFor(day);
  }
}

// For suggested dates, change the input name attribute to match
// what the controller expects
function setSuggestedDateParams(timeSlotsContainer) {
  const $inputs = $(timeSlotsContainer).find('input[type="radio"]');
  $inputs.each((ind, input) => {
    input.name = 'booking[unconfirmed_start_time]';
  });
}

function setActiveClassFor(day) {
  const activeDay = document.querySelector(`.${CLASS_ACTIVE_DAY}`);
  if (activeDay) {
    activeDay.classList.remove(CLASS_ACTIVE_DAY);
  }

  $('.fc-daygrid-day').removeClass('y-day--has-event');

  const dayEl = $(day.el);
  const $parentElement = dayEl.closest('.fc-daygrid-day'),
    activeEventClass = dayEl.hasClass('y-bg-event--available')
      ? 'y-day--has-event'
      : '';
  $parentElement.addClass([CLASS_ACTIVE_DAY, activeEventClass]);
}
