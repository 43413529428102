import { mapStyles } from './map';
import mapPin from '../../images/icons/yuup/icon-map-pin.png';
import mapPinPlus from '../../images/icons/yuup/icon-map-pin-plus.png';

document.addEventListener('turbolinks:load', () => {
  const element = document.querySelector('.js-map-mulitple-markers');

  if (element && element.dataset.mapShouldInit === 'true' && window.google) {
    element.dataset.mapShouldInit = false;
    renderMultiPinMap(element);
  }
});

// Disabled no-unused-vars rule
/* eslint-disable */
function renderMultiPinMap(element) {
  // Store array fo clusters
  let clusterMarkers = [];

  const bounds = new google.maps.LatLngBounds();
  const mapOptions = {
    mapTypeControl: false,
    styles: mapStyles,
    maxZoom: 18,
    mapTypeControlOptions: {
      mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain', 'styled_map']
    }
  };

  // Create map
  const map = new google.maps.Map(element, mapOptions);

  // Store markers
  const markers = JSON.parse(element.dataset.multipleMarkers);

  // Create info window, close when clicking elsewhere on map
  let infoWindow = new google.maps.InfoWindow();
  function infoWindowClose() {
    infoWindow.close();
  }
  google.maps.event.addListener(map, 'click', infoWindowClose);
  
  // Add OMS
  const oms = new OverlappingMarkerSpiderfier(map, {
    markersWontMove: true,
    markersWontHide: true,
    keepSpiderfied: true,
    nearbyDistance: 5
  });
  // Re-render icons when map is idle. This fixes an issue where zooming in
  // on a cluster doesn't update the marker with a +
  oms.addListener('format', updateSpiderfierIcons);
  google.maps.event.addListener(map, 'idle', () => {
    oms.h();
  });

  // Add each marker to the map and cluster array
  markers.forEach(marker => {
    (function() {
      const latLng = {
        lat: parseFloat(marker.latitude),
        lng: parseFloat(marker.longitude)
      };
      const gMarker = new google.maps.Marker({
        position: latLng,
        title: marker.name
      });

      google.maps.event.addListener(gMarker, 'click', infoWindowClose);
      oms.addMarker(gMarker, function() {
        infoWindow.setContent(buildInfoWindowContent(marker));
        infoWindow.open(map, gMarker);
      });

      clusterMarkers.push(gMarker);
      bounds.extend(latLng);
    })();
  });

  // Scale the map to fit all markers
  map.fitBounds(bounds);

  // Add marker clusters
  const markerCluster = new MarkerClusterer(map, clusterMarkers);
  markerCluster.setMaxZoom(11);
  markerCluster.setStyles(
    markerCluster.getStyles().map(function(style) {
      style.textColor = '#fff';
      return style;
    })
  );
}

function updateSpiderfierIcons(marker, status) {
  const iconURL =
    status == OverlappingMarkerSpiderfier.markerStatus.SPIDERFIED
      ? mapPin
      : status == OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE
      ? mapPinPlus
      : status == OverlappingMarkerSpiderfier.markerStatus.UNSPIDERFIABLE
      ? mapPin
      : null;
  const iconSize = new google.maps.Size(23, 32);
  marker.setIcon({
    url: iconURL,
    size: iconSize,
    scaledSize: iconSize // makes SVG icons work in IE
  });
}

function buildInfoWindowContent(marker) {
  const websiteLink = marker.website_url
  ? `<a class="d-inline-block mt-3" href='${marker.website_url}'>${marker.website_text}</a>`
  : '';
  
  return `
  <div class="info_content">
  <h3>${marker.name}</h3>
  <p class="mb-0">${marker.description}</p>
  ${websiteLink}
  </div>
  `;
}
/* eslint-enable */
