import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['icon'];

  expandRow() {
    this.element.classList.toggle('y-table-expandable--collapse-row');
    this.iconTarget.classList.toggle('y-table-expandable__icon');
  }
}
