const CONTAINER_SELECTOR = '.y-loader';
const ACTIVE_CLASS = 'y-loader--active';

document.addEventListener('ajax:send', addActiveClass);
document.addEventListener('ajax:complete', removeActiveClass);

function addActiveClass() {
  document
    .querySelectorAll(CONTAINER_SELECTOR)
    .forEach(element => element.classList.add(ACTIVE_CLASS));
}

function removeActiveClass() {
  document
    .querySelectorAll(CONTAINER_SELECTOR)
    .forEach(element => element.classList.remove(ACTIVE_CLASS));
}
