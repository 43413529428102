import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'select',
    'amountInput',
    'minimumSpendInput',
    'percentInput',
    'restrictionsInput',
    'userEmail',
    'creditExpireAt'
  ];

  initialize() {
    console.log('promotionalCode controller connected');
  }

  togglePromoTypeAttr() {
    if (this.selectTarget.value === 'amount') {
      this.amountInputTarget.disabled = false;
      this.minimumSpendInputTarget.disabled =  false;
      this.percentInputTarget.disabled = true;
      this.creditExpireAtTarget.disabled = false;
    } else {
      this.amountInputTarget.disabled = true;
      this.minimumSpendInputTarget.disabled = true;
      this.percentInputTarget.disabled = false;
      this.creditExpireAtTarget.disabled = true;
    }
  }
}
