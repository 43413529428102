import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ['button']

  static values = {
    pollingPath: String
  }

  connect() {
  }

  disconnect() {
    clearInterval(this.interval)
  }

  click(event) {
    event.target.classList.add('disabled')

    this.interval = setInterval(() => {
      Rails.ajax({
        url: this.pollingPathValue,
        type: 'get',
        dataType: 'json',
        success: (data) => {
          if (data.seats_events_generating === false) {
            clearInterval(this.interval)
            event.target.classList.remove('disabled')
          }
        }
      })
    }, 1000)
  }
}
