import addToNavigationHistory from '../helpers/turbolinksNavigationHelper';

function initTabNavigation() {
  document.addEventListener('turbolinks:load', () => {
    $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
      addToNavigationHistory(e.target);
    });
  });

  document.addEventListener('turbolinks:before-cache', () => {
    $('a[data-toggle="tab"]').off('shown.bs.tab');
  });
}

initTabNavigation();

export default initTabNavigation;
