import { Controller } from 'stimulus';

export default class extends Controller {
  updateImagePreview() {
    const input = event.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = function(e) {
        $(input)
          .parents('.js-image-preview-parent')
          .find('.js-image-upload-receiver')
          .attr('src', e.target.result);
      };

      reader.readAsDataURL(input.files[0]);
    }
  }
}
