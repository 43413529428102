import Rails from 'rails-ujs';
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

export default function() {
  document.addEventListener('turbolinks:load', () => {
    initCalendar();
  });

  initCalendar();
}

function initCalendar() {
  const calendarEl = document.getElementById('suppliers-calendar');

  if (calendarEl) {
    var calendar = new Calendar(calendarEl, {
      plugins: [timeGridPlugin, interactionPlugin],
      initialView: 'timeGridWeek',
      firstDay: 1,
      allDaySlot: false,
      fixedWeekCount: false,
      startParam: 'start_time',
      endParam: 'end_time',
      initialDate: calendarEl.dataset.initialDate,
      // For some reason setting fullcalendar gb locale
      // has an unexpected time format in row labels
      // Tried slotLabelFormat below but this appeared
      // to also have unexpected time formatting (12pm)
      // https://app.asana.com/0/1171324286853127/1188652999902596
      //
      // Setting to nz as this is the quickest solution for now
      //
      // locale: 'en-gb',
      locale: 'en-nz',
      scrollTime: '06:00:00',
      events: {
        url: calendarEl.dataset.eventsPath
      },
      // slotLabelFormat: {
      //   hour: 'numeric',
      //   hour12: true,
      //   omitZeroMinute: true,
      //   meridiem: 'short'
      // },
      eventContent: function(arg) {
        let experienceNameEl = document.createElement('div');
        let capacityEl = document.createElement('div');

        experienceNameEl.innerHTML = arg.event.extendedProps.experience_name;
        experienceNameEl.className = 'y-calendar-event--title small';
        capacityEl.innerHTML = arg.event.extendedProps.capacity;
        capacityEl.className = 'small';

        let arrayOfDomNodes = [experienceNameEl, capacityEl];
        return { domNodes: arrayOfDomNodes };
      },
      eventClick: info => {
        displayTimeslot(info.event);
      }
    });

    calendar.render();
    setupTooltipToast();

    calendarEl.addEventListener('calendar:refetchEvents', () => {
      calendar.refetchEvents();
    });
  }
}

function setupTooltipToast() {
  const animated = document.querySelector('.y-tooltip-toast'),
    tooltipToastGroup = animated.closest('.y-tooltip-toast__group');

  animated.addEventListener('animationend', () => {
    animated.classList.remove('show');
    tooltipToastGroup.classList.remove('y-tooltip-toast--active');
  });
}

function showTooltipToast() {
  const animated = document.querySelector('.y-tooltip-toast'),
    tooltipToastGroup = animated.closest('.y-tooltip-toast__group');

  animated.classList.add('show');
  tooltipToastGroup.classList.add('y-tooltip-toast--active');

  const calendarPosition =
    document.getElementById('suppliers-calendar').getBoundingClientRect().top +
    window.pageYOffset;

  if (calendarPosition > window.scrollY) {
    window.scrollTo({ top: calendarPosition, behavior: 'smooth' });
  }
}

function displayTimeslot(event) {
  Rails.ajax({
    type: 'GET',
    url: event.extendedProps.time_slot_url,
    dataType: 'script',
    success: () => {
      showTooltipToast();
    }
  });
}
