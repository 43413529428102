import { Controller } from 'stimulus';
import bootstrapValidate from 'bootstrap-validate';

export default class extends Controller {
  static targets = ['form', 'modal'];

  connect() {
    console.log('Connected to adjustment-confirm-modal controller');

    let optBox = document.getElementById('suggest_start_time');
    let customerMessageWrapper = document.querySelector('.customer-message');
    let customerMessageInput = document.getElementById(
      'state_transitioned_note'
    );

    if (optBox !== null) {
      optBox.addEventListener('change', function() {
        if (this.checked) {
          customerMessageWrapper.style.display = 'block';
          customerMessageInput.disabled = false;
        } else {
          customerMessageWrapper.style.display = 'none';
          customerMessageInput.disabled = true;
        }
      });
    }

    this.formFields.forEach(field => {
      if (this.shouldValidateField(field)) {
        const fieldName = field.dataset.validationName || 'This';
        const rule = `regex:^.*[a-zA-Z0-9]+.*$: ${fieldName} is a required field`;

        bootstrapValidate(
          field,
          rule,
          (isValid => {
            if (!isValid) {
              this.isValid = false;
            }
          }).bind(this)
        );

        field.addEventListener('blur', () => {
          field.dispatchEvent(new Event('input'));
        });
      }
    });
  }

  submit(event) {
    event.preventDefault();
    $(this.modalTarget).modal('show');
  }

  confirm() {
    if (!this.formIsValid) {
      this.firstInvalidField.focus();
    } else {
      this.formTarget.submit();
    }
  }

  shouldValidateField(field) {
    return !field.disabled && field.required;
  }

  get formFields() {
    return Array.from(this.formTarget.elements);
  }

  get formIsValid() {
    this.formFields.forEach(field => {
      if (!this.shouldValidateField(field)) {
        return;
      }

      field.dispatchEvent(new Event('input'));
    });

    return typeof this.firstInvalidField == 'undefined';
  }

  get firstInvalidField() {
    return this.formFields.find(field =>
      field.classList.contains('is-invalid')
    );
  }
}
