import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['quantity'];

  // When a gift card label is clicked, scroll the quantity input
  // into view so that it's at the bottom off the screen,
  // offset by bottomOffset
  scrollToQuantity() {
    const quantityYOffset =
      window.pageYOffset + this.quantityTarget.getBoundingClientRect().top;
    const bottomOffset = 200;
    const scrollDistance = quantityYOffset - window.innerHeight + bottomOffset;

    window.scrollTo({
      top: scrollDistance,
      behavior: 'smooth'
    });

    setTimeout(() => {
      this.quantityTarget.focus();
    }, 300);
  }
}
