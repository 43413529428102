import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'ticketStep',
    'timeslotStep',
    'bookingCalendar',
    'confirmedTicketText',
    'availabilityWarningText',
    'confirmTicketMessage',
    'allowFreeTickets'
  ];

  initialize() {
    this.exclamationIcon =
      "<svg class='y-icon-current-color y-icon -x-small y-icon-mh-25 mr-2' aria-hidden='true'><use xlink:href='#icon-exclamation-circle' /></svg>";

    if (this.data.get('booking-edit') === 'true') {
      this.timeslotStepTarget.classList.remove('y-booking-step--inactive');
    }

    const queryParams = new URLSearchParams(document.location.search)

    if (queryParams.get("editTicket") === "true") {
      this.editTicketStep()
    }
  }

  confirmTicketStep() {
    // If 0 tickets were selected, prevent user
    // from progressing to next step and show prompt
    const requiredCapacity = this.getRequiredCapacity();

    if (requiredCapacity === 0) {
      this.confirmTicketMessageTarget.innerHTML = `${this.exclamationIcon} Please select at least 1 ticket`;
      return;
    }

    // Or if tickets selected total £0, also prevent user
    // from progressing to next step and show prompt
    const totalCostPennies = this.getTotalPricePennies();
    const allowFreeTickets =
      this.allowFreeTicketsTarget.dataset.allowFreeTickets === 'true';

    if (totalCostPennies === 0 && !allowFreeTickets) {
      this.confirmTicketMessageTarget.innerHTML = `${this.exclamationIcon}  You need at least one paid ticket to purchase this experience`;
      return;
    }

    this.confirmTicketMessageTarget.innerHTML = '';
    this.confirmedTicketTextTarget.innerHTML = '';

    const ticketSummaryHTML = this.createTicketSummary();

    this.ticketStepTarget.classList.add('y-booking-step--confirmed');
    this.timeslotStepTarget.classList.remove('y-booking-step--inactive');

    // If the required (chosen) capacity is greater than available
    // show the calendar and prompt

    const selectedTimeslot = document.querySelector(
      '[data-selected-time-slot="true"]'
    );

    const availableCapacity = selectedTimeslot
      ? selectedTimeslot.dataset.capacity
      : null;

    this.confirmedTicketTextTarget.appendChild(ticketSummaryHTML);

    if (availableCapacity && availableCapacity < requiredCapacity) {
      this.timeslotStepTarget.classList.remove('y-booking-step--confirmed');
      this.availabilityWarningTextTarget.innerHTML = `<div class="y-notification y-notification--warning">${this.exclamationIcon} The number of tickets you have selected exceeds the availability on your chosen date and time, which is currently ${availableCapacity} places. Please either reduce the number of tickets in your booking or select an alternative date and time.</div>`;
    } else {
      this.availabilityWarningTextTarget.innerHTML = '';
    }

    if (
      !this.timeslotStepTarget.classList.contains(
        'y-booking-step--confirmed'
      ) ||
      availableCapacity < requiredCapacity
    ) {
      window.initBookingCalendar();
    }

    window.scrollTo(0, 0);
  }

  getRequiredCapacity() {
    return totalTicketInputs(
      input =>
        parseInt(input.dataset.participantsPerTicket) * parseInt(input.value)
    );
  }

  getTotalPricePennies() {
    return totalTicketInputs(
      input =>
        parseInt(input.dataset.participantsPerTicket) *
        parseInt(input.dataset.pricePennies)
    );
  }

  getAllowFreeTickets() {
    return totalTicketInputs(input => input.dataset.allowFreeTickets);
  }

  createTicketSummary() {
    const ticketInputs = [
      ...document.querySelectorAll('.js-price-point-input')
    ];

    let ticketSummary = document.createElement('div');

    ticketInputs.forEach(ticket => {
      const type = ticket.dataset.ticketType;
      const quantity = parseInt(ticket.value);

      const plural = quantity > 1 ? 's' : '';

      if (quantity > 0) {
        const ticketElement = document.createElement('div');
        ticketElement.innerText = `${quantity} x ${type} ticket${plural}`;
        ticketSummary.appendChild(ticketElement);
      }
    });

    return ticketSummary;
  }

  editTicketStep() {
    this.timeslotStepTarget.classList.add('y-booking-step--inactive');
    this.ticketStepTarget.classList.remove('y-booking-step--confirmed');
    const ticketStepRendered = new CustomEvent('ticketStepRendered');
    document.dispatchEvent(ticketStepRendered);
  }

  editTimeslot() {
    this.timeslotStepTarget.classList.remove('y-booking-step--confirmed');
    window.initBookingCalendar();
  }
}

function totalTicketInputs(inputFunction) {
  const ticketInputs = [...document.querySelectorAll('.js-price-point-input')];
  const ticketInputsTotal = ticketInputs
    .map(input => {
      return input.value > 0 ? inputFunction(input) : 0;
    })
    .reduce((total, value) => {
      return total + value;
    }, 0);

  return ticketInputsTotal;
}
