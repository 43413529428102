import { Controller } from 'stimulus';

let IMAGE_CYCLE;

export default class extends Controller {
  static targets = ['container', 'gallery', 'hero'];

  initialize() {
    // Ensure the gallery is not active on initial load
    // this.containerTarget.classList.remove('y-experience-thumb-gallery--active');

    // Get all experience images
    const imageArray = this.containerTarget.dataset.images.split(',');

    // Setup the initial index as a data attribute on the controller
    this.data.set('index', 0);

    this.galleryTarget.innerHTML = '';

    // If there's more than one image, prepare the next in the sequence
    if (imageArray.length > 1) {
      this.readyNextImage(imageArray[0]);
    }
  }

  disconnect() {
    this.element
      .querySelector('[data-target="experienceAnimation.container"]')
      .classList.remove('y-experience-thumb-gallery--active');

    // Clear all animation gallery content when navigating away
    clearInterval(IMAGE_CYCLE);
  }

  runEffect() {
    this.data.set('index', 0);

    // Get all experience images
    const imageArray = this.containerTarget.dataset.images.split(',');

    // If there aren't enough images for a slideshow, bail early
    if (imageArray.length < 2) {
      return;
    }

    this.containerTarget.classList.add('y-experience-thumb-gallery--active');

    IMAGE_CYCLE = setIntervalImmediately(() => {
      this.animateImage();
    }, 2500);
  }

  stopEffect() {
    this.containerTarget.classList.remove('y-experience-thumb-gallery--active');

    const galleryItems = this.galleryTarget.querySelectorAll(
      '.y-experience-thumb-gallery__item'
    );

    for (let item of galleryItems) {
      item.classList.remove(
        'y-experience-thumb-gallery__item--active',
        'y-experience-thumb-gallery__item--zoom'
      );
    }

    this.data.set('index', 0);
    clearInterval(IMAGE_CYCLE);
  }

  readyNextImage(url) {
    // Create an image wrapper
    const imgDiv = document.createElement('div');
    imgDiv.className = 'y-experience-thumb-gallery__item';
    // Create a DOM image element
    const img = new Image();
    img.src = url;
    img.className = 'y-experience-thumb-gallery__image';
    // Append the image to the wrapper
    imgDiv.appendChild(img);
    // Get the current first child element and insert the new image div before it
    this.galleryTarget.insertAdjacentElement('beforeend', imgDiv);
  }

  animateImage() {
    // Get the current index
    let currentIndex = parseInt(this.data.get('index'));

    // Get all thumbnail gallery items
    const galleryItems = this.galleryTarget.querySelectorAll(
      '.y-experience-thumb-gallery__item'
    );

    if (currentIndex == 0) {
      for (let item of galleryItems) {
        item.classList.remove(
          'y-experience-thumb-gallery__item--active',
          'y-experience-thumb-gallery__item--zoom'
        );
      }
    }

    // Get the images array
    const imageArray = this.containerTarget.dataset.images.split(',');

    // If there's another image to add, ready it
    if (imageArray[galleryItems.length]) {
      this.readyNextImage(imageArray[galleryItems.length]);
    }

    galleryItems[currentIndex].classList.add(
      'y-experience-thumb-gallery__item--active',
      'y-experience-thumb-gallery__item--zoom'
    );

    if (currentIndex + 1 == imageArray.length) {
      this.data.set('index', 0);
      galleryItems[0].classList.remove(
        'y-experience-thumb-gallery__item--zoom'
      );
    } else {
      this.data.set('index', currentIndex + 1);
    }
  }
}

// This ensures an initial firing of the setInterval callback
function setIntervalImmediately(func, interval) {
  func();
  return setInterval(func, interval);
}
