import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox'];

  checkboxCheck() {
    console.log('button clicked!');
    this.checkboxTarget.checked = !this.checkboxTarget.checked;
  }
}
