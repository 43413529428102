import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['field', 'hint'];

  showHint() {
    this.hintTarget.classList.add('y-field-hint--active');
  }

  hideHint() {
    this.hintTarget.classList.remove('y-field-hint--active');
  }
}
