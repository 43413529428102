import { Controller } from 'stimulus';

const CLONED_OPTIONS_CONTAINER_ID = 'all-options-container';

export default class extends Controller {
  static targets = ['filterSelect', 'resultsSelect'];

  connect() {
    // clones all the options and appends to the body
    // then when we filter we can select from these options.

    const clonedOptionsContainer = document.createElement('div');

    clonedOptionsContainer.id = CLONED_OPTIONS_CONTAINER_ID;
    clonedOptionsContainer.style.display = 'none';

    document.querySelector('body').appendChild(clonedOptionsContainer);

    const clone = this.resultsSelectTarget.cloneNode(true);

    clone.querySelectorAll('option').forEach(option => {
      clonedOptionsContainer.appendChild(option);
    });

    // in case a filter option is selected on page load
    this.filter();
  }

  filter() {
    this.resultsSelectTarget.innerHTML = '';

    // insert a blank option
    this.resultsSelectTarget.appendChild(document.createElement('option'));

    let optionSelector;
    if (this.filterSelectTarget.value) {
      optionSelector = `option[data-filter-value="${this.filterSelectTarget.value}"]`;
    } else {
      optionSelector = 'option';
    }

    const filteredOptions = document
      .getElementById(CLONED_OPTIONS_CONTAINER_ID)
      .querySelectorAll(optionSelector);

    // insert the filtered options
    filteredOptions.forEach(option => {
      if (option.value) {
        this.resultsSelectTarget.appendChild(option.cloneNode(true));
      }
    });
  }
}
