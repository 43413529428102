import { Controller } from 'stimulus';
import formHelper from '../helpers/formHelper';

export default class extends Controller {
  static targets = ['toggler', 'collapsible'];

  connect() {
    if (this.data.get('single')) {
      this.singleToggle();
    }
  }

  toggle() {
    const collapseOn = JSON.parse(this.data.get('collapseOn'));

    if (collapseOn.includes(this.togglerTarget.value)) {
      $(this.collapsibleTarget).collapse('hide');
      formHelper.disableDescendants(this.collapsibleTarget);
    } else {
      $(this.collapsibleTarget).collapse('show');
      formHelper.enableDescendants(this.collapsibleTarget);
    }
  }

  singleToggle() {
    let state = this.togglerTarget.checked

    if (this.data.get('invert')) { state = !state }

    if (state) {
      $(this.collapsibleTarget).collapse('show');
      formHelper.enableDescendants(this.collapsibleTarget);
    } else {
      $(this.collapsibleTarget).collapse('hide');
      formHelper.disableDescendants(this.collapsibleTarget);
    }
  }

  singleDisable() {
    let state = this.togglerTarget.checked

    if (this.data.get('invert')) { state = !state }

    if (state) {
      formHelper.enableDescendants(this.collapsibleTarget);
    } else {
      formHelper.disableDescendants(this.collapsibleTarget);
    }
  }
}
