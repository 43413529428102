import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import datesHelper from '../helpers/datesHelper';
import interactionPlugin from '@fullcalendar/interaction';

export default function initCalendar() {
  document.addEventListener('turbolinks:load', () => {
    loadCalendar();
    humanizeDates();
  });
  humanizeDates();
  loadCalendar();
}

function loadCalendar() {
  const $calendarModal = $('#calendarModal');
  let modalCalendar;

  $calendarModal.on('show.bs.modal', function(event) {
    // The calendar modal title can be set via a data attr
    const modalTitle = event.relatedTarget.dataset.title;

    if (modalTitle) {
      $calendarModal.find('#calendarModalTitle').text(modalTitle);
    }
  });

  // When the calendar modal shows, render the calendar
  $calendarModal.on('shown.bs.modal', function(event) {
    const calendarEl = document.getElementById('modal-calendar'),
      // The linked input with the calendar is either an element id that
      // is passed via a data attr or the input itself
      $linkedInput = $(event.relatedTarget.dataset.input),
      $linkedInputVal = $linkedInput.val(),
      $triggerEl = $(event.relatedTarget),
      allowPastDates = event.relatedTarget.dataset.allowPastDates,
      preventFutureDates = event.relatedTarget.dataset.preventFutureDates;

    let startDate = null;

    if (event.relatedTarget.dataset.input == '#end-date') {
      // Get start date value, if it exists
      startDate = $('#start-date').val();
    }

    modalCalendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      // If the input already has a date, open to that month
      initialDate: $linkedInputVal ? $linkedInputVal : null,
      firstDay: 1,
      validRange: {
        start: startDate,
        end: preventFutureDates ? new Date() : null
      },
      dateClick: function(info) {
        // Unless specifically allowed, return if a past date is clicked
        if (!allowPastDates && info.dayEl.classList.contains('fc-day-past')) {
          return;
        }

        // If a future date is clicked and isn't permitted, return
        if (
          preventFutureDates &&
          info.dayEl.classList.contains('fc-day-future')
        ) {
          return;
        }

        // If a start date is reselected and is after the end date, clear it
        if (
          $linkedInput.attr('id') === 'start-date' &&
          endDateIsInvalid(info.dateStr)
        ) {
          const $endDateInput = $('#end-date');
          const $endDateButton = $('[data-input="#end-date"]');
          $endDateInput.val('');
          $endDateButton
            .find('.y-select-button__text')
            .text($endDateButton.data('text'));
        }

        // When a calendar date is clicked, update input
        $linkedInput.val(info.dateStr);

        // If a y-calendar-button opened the calendar, update it
        const humanDate = $triggerEl[0].dataset.shortDateFormat
          ? datesHelper.humanDateShort(info.dateStr)
          : datesHelper.humanDate(info.dateStr);

        const event = new CustomEvent(`dateSelected`, {
          bubbles: true,
          cancelable: true
        });
        $triggerEl[0].dispatchEvent(event);

        $triggerEl.find('span').text(humanDate);

        // Hack to stop calendar touch events on non Chrome-based browsers 'going through'
        // the full calendar modal and activating select boxes below it. It doesn't stop
        // the weird touch behavior, it just makes the things underneath it unclickable
        // until it's over.
        const filters = $('#searchFilters');
        filters.addClass('mobile-no-click');
        setTimeout(() => {
          filters.removeClass('mobile-no-click');
        }, 250);
        //

        $calendarModal.modal('hide');
        modalCalendar.destroy();
      }
    });

    modalCalendar.render();

    // If date already selected, highlight it
    if ($linkedInputVal) {
      $(`[data-date="${$linkedInputVal}"]`).addClass('active');
    }
  });

  $calendarModal.on('hidden.bs.modal', function() {
    modalCalendar.destroy();
  });
}

function humanizeDates() {
  const buttons = document.querySelectorAll('[data-input]');

  buttons.forEach(element => {
    const relatedInput = document.querySelector(element.dataset.input),
      humanDate = datesHelper.humanDate(relatedInput.value),
      humanDateShort = datesHelper.humanDateShort(relatedInput.value);

    if (relatedInput.value) {
      element.querySelector('.y-select-button__text').innerText = element
        .dataset.shortDateFormat
        ? humanDateShort
        : humanDate;
    }
  });
}

function endDateIsInvalid(selectedStartDate) {
  if ($('input#end-date').val() === '') return false;

  const startDate = new Date(selectedStartDate);
  const endDate = new Date($('input#end-date').val());

  return startDate > endDate;
}
