import { Controller } from 'stimulus';
import debounce from 'lodash/debounce';

const BREAKPOINT_DESKTOP = 992;

export default class extends Controller {
  static targets = ['video'];

  connect() {
    this.storeBreakpoint();

    this.breakpointCheck = debounce(this.breakpointCheck, 200).bind(this);
    this.breakpointCheckEvent = this.breakpointCheck.bind(this);
    window.addEventListener('resize', this.breakpointCheckEvent);
  }

  breakpointCheck() {
    // If viewport width passes over the desktop breakpoint, stop all videos
    if (
      (this.currentBreakpoint < BREAKPOINT_DESKTOP &&
        window.innerWidth >= BREAKPOINT_DESKTOP) ||
      (this.currentBreakpoint >= BREAKPOINT_DESKTOP &&
        window.innerWidth < BREAKPOINT_DESKTOP)
    ) {
      this.videoTargets.forEach(el => {
        el.contentWindow.postMessage(
          '{"event":"command","func":"stopVideo","args":""}',
          '*'
        );
      });
    }

    this.storeBreakpoint();
  }

  storeBreakpoint() {
    this.currentBreakpoint = window.innerWidth;
  }
}
