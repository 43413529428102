import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    console.log('Bank controller is connected');

    var $sortCodeInputs = $('.y-sort-code');
    $sortCodeInputs.on('keyup', function() {
      if ($(this).val().length == 2) {
        let $index = $sortCodeInputs.index(this);
        if ($index + 1 !== $sortCodeInputs.length) {
          $sortCodeInputs.eq($index + 1).focus();
        } else {
          $sortCodeInputs.eq($index).trigger('blur');
        }
      }
    });
  }
}
