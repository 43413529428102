import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['source', 'prompt'];

  connect() {
    if (document.queryCommandSupported('copy')) {
      this.element.querySelector('button').classList.remove('d-none');
    }
  }

  copy() {
    const controller = this;

    this.sourceTarget.select();
    document.execCommand('copy');

    this.promptTarget.innerText = 'Copied!';

    setTimeout(function() {
      controller.promptTarget.innerText = 'Copy';
    }, 1000);
  }
}
